import config from "@/config"
import http from "@/utils/request"

export default {
	sale: {
		listSaleOrder: {
			url: `${config.API_URL}/erp/order/sale/list`,
			name: "查询销售订单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listUnPacking: {
			url: `${config.API_URL}/erp/order/sale/listUnPacking`,
			name: "查询销售订单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listSaleOrderOfCustomer: {
			url: `${config.API_URL}/erp/order/sale/listOfCustomer`,
			name: "查询销售订单列表(根据客户分类)",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getSaleOrder: {
			url: `${config.API_URL}/erp/order/sale/`,
			name: "查询销售订单详细",
			get: async function (orderId) {
				return await http.get(this.url + orderId);
			}
		},
		getSaleOrderByCode: {
			url: `${config.API_URL}/erp/order/sale/getInfoByCode/`,
			name: "查询销售订单详细",
			get: async function (orderCode) {
				return await http.get(this.url + orderCode);
			}
		},
		addSaleOrder: {
			url: `${config.API_URL}/erp/order/sale`,
			name: "新增销售订单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateSaleOrder: {
			url: `${config.API_URL}/erp/order/sale`,
			name: "修改销售订单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWarn: {
			url: `${config.API_URL}/erp/order/sale/delWarn/`,
			name: "查询销售订单详细",
			get: async function (orderId) {
				return await http.get(this.url + orderId);
			}
		},
		delSaleOrder: {
			url: `${config.API_URL}/erp/order/sale/`,
			name: "删除销售订单",
			delete: async function (orderId) {
				return await http.delete(this.url + orderId, orderId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/order/sale/export`,
			name: "导出销售订单",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "sale_order");
			}
		},
		generatePlan: {
			url: `${config.API_URL}/erp/order/sale/generatePlan`,
			name: "生产生产计划",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		listSaleOrderOutWarehouse: {
			url: `${config.API_URL}/erp/order/sale/listSaleOrderOutWarehouse`,
			name: "查询销售订单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportSaleOrderOutWarehouse: {
			url: `${config.API_URL}/erp/order/sale/exportSaleOrderOutWarehouse`,
			name: "导出销售订单",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "sale_order_out_warehouse");
			}
		},
		approval: {
			url: `${config.API_URL}/erp/order/sale/approval`,
			name: "审批",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		importProductTemplate: {
			url: `${config.API_URL}/erp/order/sale/product/importTemplate`,
			name: "下载模板",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "saleProductDetailImport");
			}
		},
		getPrintRecord: {
			url: `${config.API_URL}/erp/order/sale/getPrintRecord/`,
			name: "获取打印标签记录",
			get: async function (orderProductDetailId) {
				return await http.get(this.url + orderProductDetailId);
			}
		},
		getSaleOrderDetailBy1010: {
			url: `${config.API_URL}/erp/order/sale/getSaleOrderDetailBy1010`,
			name: "从1010数据库查询订单产品信息",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportPart: {
			url: `${config.API_URL}/erp/order/sale/exportPart`,
			name: "导出配件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "sale_order_product_parts");
			}
		},
		getPartsByOrderProductId: {
			url: `${config.API_URL}/erp/order/sale/getPartsByOrderProductId/`,
			name: "获取产品配件",
			get: async function (orderProductId) {
				return await http.get(this.url + orderProductId);
			}
		},
	},
	saleOrderproduct: {
		getSaleOrderProductByOrderid: {
			url: `${config.API_URL}/erp/order/saleOrderproduct/getSaleOrderProductByOrderid/`,
			name: "获取销售订单产品明细详细信息",
			get: async function (orderId) {
				return await http.get(this.url + orderId);
			}
		},
	},
	progress: {
		listOrderProgress: {
			url: `${config.API_URL}/erp/order/executeProgress/list`,
			name: "查询销售订单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	},
	purchase: {
		listPurchaseOrder: {
			url: `${config.API_URL}/erp/order/purchase/list`,
			name: "查询采购订单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listOrderItemId: {
			url: `${config.API_URL}/erp/order/purchase/listOrderItemId`,
			name: "根据物料id查找对应的采购单",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listPurchaseOrderOfSupplier: {
			url: `${config.API_URL}/erp/order/purchase/listOfSupplier`,
			name: "查询采购订单列表(按供应商分类)",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getPurchaseOrder: {
			url: `${config.API_URL}/erp/order/purchase/`,
			name: "查询采购订单详细",
			get: async function (id) {
				return await http.get(this.url + id);
			}
		},
		getPurchaseOrderCode: {
			url: `${config.API_URL}/erp/order/purchase/getPurchaseOrderCode/`,
			name: "根据采购订单code获取详情",
			get: async function (purchaseCode) {
				return await http.get(this.url + purchaseCode);
			}
		},
		addPurchaseOrder: {
			url: `${config.API_URL}/erp/order/purchase`,
			name: "新增采购订单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updatePurchaseOrder: {
			url: `${config.API_URL}/erp/order/purchase`,
			name: "修改采购订单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWarn: {
			url: `${config.API_URL}/erp/order/purchase/delWarn/`,
			name: "查询采购订单详细",
			get: async function (id) {
				return await http.get(this.url + id);
			}
		},
		delPurchaseOrder: {
			url: `${config.API_URL}/erp/order/purchase/`,
			name: "删除采购订单",
			delete: async function (id) {
				return await http.delete(this.url + id, id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/order/purchase/export`,
			name: "导出采购订单",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "purchase_order");
			}
		},
		listSupplierMaterial: {
			url: `${config.API_URL}/erp/order/purchase/listSupplierMaterial`,
			name: "查询供应商物料列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		approval: {
			url: `${config.API_URL}/erp/order/purchase/approval`,
			name: "审批",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		listPurchaseOrderMaterial: {
			url: `${config.API_URL}/erp/order/purchase/listPurchaseOrderMaterial`,
			name: "查询采购物料列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	}
}
