import config from "@/config"
import http from "@/utils/request"

export default {
	/**
	 * 客户类别
	 */
	clientCategory:{
		listClientCategory: {
			url: `${config.API_URL}/erp/client/clientCategory/list`,
			name: "查询客户类别列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getClientCategory: {
			url: `${config.API_URL}/erp/client/clientCategory/`,
			name: "查询客户类别详细",
			get: async function(clientCategoryId) {
				return await http.get(this.url +clientCategoryId);
			}
		},
		addClientCategory: {
			url: `${config.API_URL}/erp/client/clientCategory`,
			name: "新增客户类别",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateClientCategory: {
			url: `${config.API_URL}/erp/client/clientCategory`,
			name: "修改客户类别",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delClientCategory: {
			url: `${config.API_URL}/erp/client/clientCategory/`,
			name: "删除客户类别",
			delete: async function(clientCategoryId) {
				return await http.delete(this.url +clientCategoryId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/client/clientCategory/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "clientCategory");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/client/clientCategory/changeStatus`,
			name: "客户类别状态修改",
			put: async function(vendorProfessionId, status){
				const data = {
					vendorProfessionId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 客户营业性质
	 */
	clientBusinessNature:{
		listClientBusinessNature: {
			url: `${config.API_URL}/erp/client/clientBusinessNature/list`,
			name: "查询客户营业性质列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getClientBusinessNature: {
			url: `${config.API_URL}/erp/client/clientBusinessNature/`,
			name: "查询客户营业性质详细",
			get: async function(clientBusinessNatureId) {
				return await http.get(this.url +clientBusinessNatureId);
			}
		},
		addClientBusinessNature: {
			url: `${config.API_URL}/erp/client/clientBusinessNature`,
			name: "新增客户营业性质",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateClientBusinessNature: {
			url: `${config.API_URL}/erp/client/clientBusinessNature`,
			name: "修改客户营业性质",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delClientBusinessNature: {
			url: `${config.API_URL}/erp/client/clientBusinessNature/`,
			name: "删除客户营业性质",
			delete: async function(clientBusinessNatureId) {
				return await http.delete(this.url +clientBusinessNatureId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/client/clientBusinessNature/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "clientBusinessNature");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/client/clientCategory/changeStatus`,
			name: "客户营业性质状态修改",
			put: async function(clientBusinessNatureId, status){
				const data = {
					clientBusinessNatureId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 客户等级
	 */
	clientGrade:{
		listClientGrade: {
			url: `${config.API_URL}/erp/client/clientGrade/list`,
			name: "查询客户等级列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getClientGrade: {
			url: `${config.API_URL}/erp/client/clientGrade/`,
			name: "查询客户等级详细",
			get: async function(clientGradeId) {
				return await http.get(this.url +clientGradeId);
			}
		},
		addClientGrade: {
			url: `${config.API_URL}/erp/client/clientGrade`,
			name: "新增客户等级",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateClientGrade: {
			url: `${config.API_URL}/erp/client/clientGrade`,
			name: "修改客户等级",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delClientGrade: {
			url: `${config.API_URL}/erp/client/clientGrade/`,
			name: "删除客户等级",
			delete: async function(clientGradeId) {
				return await http.delete(this.url +clientGradeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/client/clientGrade/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "clientGrade");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/client/clientGrade/changeStatus`,
			name: "客户等级状态修改",
			put: async function(clientGradeId, status){
				const data = {
					clientGradeId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 客户行业
	 */
	clientProfession:{
		listClientProfession: {
			url: `${config.API_URL}/erp/client/clientProfession/list`,
			name: "查询客户行业列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getClientProfession: {
			url: `${config.API_URL}/erp/client/clientProfession/`,
			name: "查询客户行业详细",
			get: async function(clientProfessionId) {
				return await http.get(this.url +clientProfessionId);
			}
		},
		addClientProfession: {
			url: `${config.API_URL}/erp/client/clientProfession`,
			name: "新增客户行业",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateClientProfession: {
			url: `${config.API_URL}/erp/client/clientProfession`,
			name: "修改客户行业",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delClientProfession: {
			url: `${config.API_URL}/erp/client/clientProfession/`,
			name: "删除客户行业",
			delete: async function(clientProfessionId) {
				return await http.delete(this.url +clientProfessionId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/client/clientProfession/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "clientProfession");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/client/clientProfession/changeStatus`,
			name: "客户行业状态修改",
			put: async function(clientProfessionId, status){
				const data = {
					clientProfessionId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 客户管理
	 */
	client:{
		listClient: {
			url: `${config.API_URL}/erp/client/list`,
			name: "查询客户列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		listInfos: {
			url: `${config.API_URL}/erp/client/listInfos`,
			name: "查询客户列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getClient: {
			url: `${config.API_URL}/erp/client/`,
			name: "查询客户详细",
			get: async function(clientId) {
				return await http.get(this.url +clientId);
			}
		},
		addClient: {
			url: `${config.API_URL}/erp/client`,
			name: "新增客户",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateClient: {
			url: `${config.API_URL}/erp/client`,
			name: "修改客户",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delClient: {
			url: `${config.API_URL}/erp/client/`,
			name: "删除客户",
			delete: async function(clientId) {
				return await http.delete(this.url +clientId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/client/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`vendor`");
			}
		},
		listInfo: {
			url: `${config.API_URL}/erp/client/listInfo`,
			name: "查询客户关联信息",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	}
}
