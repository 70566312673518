import config from "@/config"
import http from "@/utils/request"

export default {
  rule:{
    genCode: {
      url: `${config.API_URL}/system/autocode/get/`,
      name: "",
      get: async function(ruleCode){
        return await http.get(this.url+ruleCode);
      }
    },
    listRule: {
      url: `${config.API_URL}/system/autocode/rule/list`,
      name: "查询字典类型列表",
      get: async function(query){
        return await http.get(this.url,query);
      }
    },
    getRule: {
      url: `${config.API_URL}/system/autocode/rule/`,
      name: "查询字典类型详细",
      get: async function(ruleId){
        return await http.get(this.url+ruleId);
      }
    },
    addRule: {
      url: `${config.API_URL}/system/autocode/rule`,
      name: "新增字典类型",
      post: async function(data){
        return await http.post(this.url,data);
      }
    },
    updateRule: {
      url: `${config.API_URL}/system/autocode/rule`,
      name: "修改字典类型",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    delRule: {
      url: `${config.API_URL}/system/autocode/rule/`,
      name: "删除字典类型",
      delete: async function(ruleId){
        return await http.delete(this.url+ruleId);
      }
    },
  },
  part:{
    listPart: {
      url: `${config.API_URL}/system/autocode/part/list`,
      name: "查询规则组成",
      get: async function(query){
        return await http.get(this.url,query);
      }
    },
    getPart: {
      url: `${config.API_URL}/system/autocode/part/`,
      name: "查询规则组成详细",
      get: async function(partId){
        return await http.get(this.url+ partId);
      }
    },
    addPart: {
      url: `${config.API_URL}/system/autocode/part`,
      name: "新增规则组成",
      post: async function(data){
        return await http.post(this.url,data);
      }
    },
    updatePart: {
      url: `${config.API_URL}/system/autocode/part`,
      name: "修改规则组成",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    delPart: {
      url: `${config.API_URL}/system/autocode/part/`,
      name: "删除规则组成",
      delete: async function(partIds){
        return await http.delete(this.url + partIds);
      }
    },
  }
}