import config from "@/config"
import http from "@/utils/request"

export default {
	saleOrder: {
		selectSOProduced: {
			url: `${config.API_URL}/mes/sale/order/list`,
			name: "查询销售订单",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		}
	}
}
