import config from "@/config"
import http from "@/utils/request"

export default {
	assistplan: {
		listAssistplan: {
			url: `${config.API_URL}/mes/assist/assistplan/list`,
			name: "查询辅助作业方案列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getAssistplan: {
			url: `${config.API_URL}/mes/assist/assistplan/`,
			name: "查询辅助作业方案详细",
			get: async function(assistplanId) {
				return await http.get(this.url +assistplanId);
			}
		},
		addAssistplan: {
			url: `${config.API_URL}/mes/assist/assistplan`,
			name: "新增辅助作业方案",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateAssistplan: {
			url: `${config.API_URL}/mes/assist/assistplan`,
			name: "修改辅助作业方案",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delAssistplan: {
			url: `${config.API_URL}/mes/assist/assistplan/`,
			name: "删除辅助作业方案",
			delete: async function(assistplanId) {
				return await http.delete(this.url +assistplanId);
			}
		},
	},
	assistPlanRecord: {
		listAssistplanRecord: {
			url: `${config.API_URL}/mes/assist/assistplanRecord/list`,
			name: "查询检验单辅助作业记录列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		listAssistplanRecordIndex: {
			url: `${config.API_URL}/mes/assist/assistplanRecord/listIndex`,
			name: "查询检验单辅助作业记录列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getAssistplanRecord: {
			url: `${config.API_URL}/mes/assist/assistplanRecord/`,
			name: "查询检验单辅助作业记录详细",
			get: async function(recordId) {
				return await http.get(this.url +recordId);
			}
		},
		listByWorkOrTask: {
			url: `${config.API_URL}/mes/assist/assistplanRecord/listByWorkOrTask`,
			name: "根据工单id或任务id 查询辅助作业记录列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		delAssistplanRecord: {
			url: `${config.API_URL}/mes/assist/assistplanRecord/`,
			name: "删除辅助作业方案",
			delete: async function(assistplanRecordId) {
				return await http.delete(this.url +assistplanRecordId);
			}
		},
		addOutAssistplanRecord: {
			url: `${config.API_URL}/mes/assist/assistplanRecord/addOut`,
			name: "新增表外辅助作业记录",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
	},
	kiln: {
		listKiln: {
			url: `${config.API_URL}/mes/assist/kiln/list`,
			name: "查询窑炉烧成记录列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getKiln: {
			url: `${config.API_URL}/mes/assist/kiln/`,
			name: "查询窑炉烧成记录详细",
			get: async function(kilnId) {
				return await http.get(this.url +kilnId);
			}
		},
		addKiln: {
			url: `${config.API_URL}/mes/assist/kiln`,
			name: "新增窑炉烧成记录",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateKiln: {
			url: `${config.API_URL}/mes/assist/kiln`,
			name: "修改窑炉烧成记录",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delKiln: {
			url: `${config.API_URL}/mes/assist/kiln/`,
			name: "删除窑炉烧成记录",
			delete: async function(kilnId) {
				return await http.delete(this.url +kilnId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/assist/kiln/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`kiln`");
			}
		},
	}

}
