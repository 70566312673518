import config from "@/config"
import http from "@/utils/request"

export default {
	methods: {
		getMinioFileInfo: {
			url: `${config.API_URL}/file/getMinioFileInfo`,
			name: "获取文件信息",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		}
	},
}
