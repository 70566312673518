import config from "@/config"
import http from "@/utils/request"

export default {
	account: {
		listAccount: {
			url: `${config.API_URL}/erp/fund/account/list`,
			name: "查询erp结算账户列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getAccount: {
			url: `${config.API_URL}/erp/fund/account/`,
			name: "查询erp结算账户详细",
			get: async function(accountId) {
				return await http.get(this.url +accountId);
			}
		},
		addAccount: {
			url: `${config.API_URL}/erp/fund/account`,
			name: "新增erp结算账户",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateAccount: {
			url: `${config.API_URL}/erp/fund/account`,
			name: "修改erp结算账户",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delAccount: {
			url: `${config.API_URL}/erp/fund/account/`,
			name: "删除erp结算账户",
			delete: async function(accountId) {
				return await http.delete(this.url +accountId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/fund/account/export`,
			name: "导出",
			post: async function (data,config = {}) {
				return await http.postDom(this.url, data, config, "client");
			}
		},
	},
	fund: {
		listFund: {
			url: `${config.API_URL}/erp/fund/fund/list`,
			name: "查询erp资金管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getFund: {
			url: `${config.API_URL}/erp/fund/fund/`,
			name: "查询erp资金管理详细",
			get: async function(fundId) {
				return await http.get(this.url +fundId);
			}
		},
		addFund: {
			url: `${config.API_URL}/erp/fund/fund`,
			name: "新增erp资金管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateFund: {
			url: `${config.API_URL}/erp/fund/fund`,
			name: "修改erp资金管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delFund: {
			url: `${config.API_URL}/erp/fund/fund/`,
			name: "删除erp资金管理",
			delete: async function(fundId) {
				return await http.delete(this.url +fundId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/fund/fund/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`fund`");
			}
		},
	},
	fundPool: {
		listFundPool: {
			url: `${config.API_URL}/erp/fund/pool/list`,
			name: "查询erp资金池列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getFundPoolDetails: {
			url: `${config.API_URL}/erp/fund/pool/details`,
			name: "查询erp资金池列表详情",
			get: async function(data) {
				return await http.get(this.url ,data);
			}
		},
	}
}
