<template>
	<el-config-provider :locale="locale" :size="config.size" :zIndex="config.zIndex" :button="config.button">
		<router-view></router-view>
	</el-config-provider>
</template>

<script>
	import colorTool from '@/utils/color'
	import store from "@/store";
	export default {
		name: 'App',
		data() {
			return {
				config: {
					size: "default",
					zIndex: 2000,
					button: {
						autoInsertSpace: false
					}
				}
			}
		},
		computed: {
			locale(){
				return this.$i18n.messages[this.$i18n.locale].el
			},
		},
		created() {
			//设置主题颜色
			const app_color = this.$CONFIG.COLOR || this.$TOOL.data.get('APP_COLOR')
			if(app_color){
				document.documentElement.style.setProperty('--el-color-primary', app_color);
				for (let i = 1; i <= 9; i++) {
					document.documentElement.style.setProperty(`--el-color-primary-light-${i}`, colorTool.lighten(app_color,i/10));
				}
				for (let i = 1; i <= 9; i++) {
					document.documentElement.style.setProperty(`--el-color-primary-dark-${i}`, colorTool.darken(app_color,i/10));
				}
			}
			this.getproject()
			this.getlogo()
			this.getVueLogo()
		},
		methods:{
			getproject(){
				this.$API.system.trademarkManagement.listTrademarkManagement.get({trademarkManagementKey: 'sys.project.name',status:'0'}).then(res => {
					if (res.code == 200 && res.rows.length > 0) {
						store.dispatch('GetSysProjectName', res.rows[0].trademarkManagementValue)
						document.title=res.rows[0].trademarkManagementValue
						localStorage.setItem("SysProjectName", res.rows[0].trademarkManagementValue)
					}
				})
			},
			getlogo(){
				this.$API.system.trademarkManagement.listTrademarkManagement.get({trademarkManagementKey: 'sys.logo',status:'0'}).then(res => {
					if (res.code == 200  && res.rows.length > 0) {
						store.dispatch('GetSysLogo',res.rows[0].trademarkManagementValue)
						var link = document.querySelector("link[rel*='icon']");
						link.href=res.rows[0].trademarkManagementValue;
						localStorage.setItem("SysLogo", res.rows[0].trademarkManagementValue)
					}
				})
			},
			getVueLogo(){
				this.$API.system.trademarkManagement.listTrademarkManagement.get({trademarkManagementKey: 'sys.vue.logo',status:'0'}).then(res => {
					if (res.code == 200  && res.rows.length > 0) {
						store.dispatch('GetSysVueLogo',res.rows[0].trademarkManagementValue)
						localStorage.setItem("SysVueLogo", res.rows[0].trademarkManagementValue)
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '@/style/style.scss';
</style>
