import config from "@/config"
import http from "@/utils/request"

export default {
	calendar: {
		listCalendars: {
			url: `${config.API_URL}/mes/cal/calendar/list`,
			name: "查询排班列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	},
	calholiday: {
		listCalholiday: {
			url: `${config.API_URL}/mes/cal/calholiday/list`,
			name: "查询节假日设置列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getCalholiday: {
			url: `${config.API_URL}/mes/cal/calholiday/`,
			name: "查询节假日设置详细",
			get: async function (holidayId) {
				return await http.get(this.url + holidayId);
			}
		},
		addCalholiday: {
			url: `${config.API_URL}/mes/cal/calholiday`,
			name: "新增节假日设置",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateCalholiday: {
			url: `${config.API_URL}/mes/cal/calholiday`,
			name: "修改节假日设置",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delCalholiday: {
			url: `${config.API_URL}/mes/cal/calholiday/`,
			name: "删除节假日设置",
			delete: async function (holidayId) {
				return await http.delete(this.url + holidayId);
			}
		}
	},
	calplan: {
		listCalplan: {
			url: `${config.API_URL}/mes/cal/calplan/list`,
			name: "查询排班计划列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getCalplan: {
			url: `${config.API_URL}/mes/cal/calplan/`,
			name: "查询排班计划详细",
			get: async function (planId) {
				return await http.get(this.url + planId);
			}
		},
		addCalplan: {
			url: `${config.API_URL}/mes/cal/calplan`,
			name: "新增排班计划",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateCalplan: {
			url: `${config.API_URL}/mes/cal/calplan`,
			name: "修改排班计划",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delCalplan: {
			url: `${config.API_URL}/mes/cal/calplan/`,
			name: "删除排班计划",
			delete: async function (planId) {
				return await http.delete(this.url + planId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/cal/calplan/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "calplan");
			}
		}


	},
	planteam: {
		listPlanteam: {
			url: `${config.API_URL}/mes/cal/planteam/list`,
			name: "查询计划班组列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		addPlanteam: {
			url: `${config.API_URL}/mes/cal/planteam`,
			name: "新增计划班组",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		delPlanteam: {
			url: `${config.API_URL}/mes/cal/planteam/`,
			name: "删除计划班组",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		}


	},
	shift: {
		listShift: {
			url: `${config.API_URL}/mes/cal/shift/list`,
			name: "查询计划班次列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getShift: {
			url: `${config.API_URL}/mes/cal/shift/`,
			name: "查询计划班次详细",
			get: async function (shiftId) {
				return await http.get(this.url+shiftId);
			}
		},
		addShift: {
			url: `${config.API_URL}/mes/cal/shift`,
			name: "新增计划班次",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateShift: {
			url: `${config.API_URL}/mes/cal/shift`,
			name: "修改计划班次",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delShift: {
			url: `${config.API_URL}/mes/cal/shift/`,
			name: "删除计划班次",
			delete: async function (shiftId) {
				return await http.delete(this.url+shiftId);
			}
		}

	},
	team: {
		listTeam: {
			url: `${config.API_URL}/mes/cal/team/list`,
			name: "查询班组列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listAllTeam: {
			url: `${config.API_URL}/mes/cal/team/listAll`,
			name: "查询班组列表",
			get: async function () {
				return await http.get(this.url);
			}
		},
		getTeam: {
			url: `${config.API_URL}/mes/cal/team/`,
			name: "查询班组详细",
			get: async function (teamId) {
				return await http.get(this.url + teamId);
			}
		},
		addTeam: {
			url: `${config.API_URL}/mes/cal/team`,
			name: "新增部门",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateTeam: {
			url: `${config.API_URL}/mes/cal/team`,
			name: "修改班组",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delTeam: {
			url: `${config.API_URL}/mes/cal/team/`,
			name: "删除班组",
			delete: async function (teamId) {
				return await http.delete(this.url + teamId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/cal/team/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "team");
			}
		},
		getTeamInfoByWorkstationId: {
			url: `${config.API_URL}/mes/cal/team/getTeamInfoByWorkstationId/`,
			name: "根据站点id 查询站点下第一个班组信息",
			get: async function (workstationId) {
				return await http.get(this.url + workstationId);
			}
		},
		getTeamAndOtherMember: {
			url: `${config.API_URL}/mes/cal/team/getTeamAndOtherMember/`,
			name: "查询站点信息及成员",
			get: async function (teamId) {
				return await http.get(this.url + teamId);
			}
		},
	},
	teammember: {
		listTeammember: {
			url: `${config.API_URL}/mes/cal/teammember/list`,
			name: "查询班组成员列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getTeammember: {
			url: `${config.API_URL}/mes/cal/teammember/`,
			name: "查询班组成员详细",
			get: async function (memberId) {
				return await http.get(this.url + memberId);
			}
		},
		addTeammember: {
			url: `${config.API_URL}/mes/cal/teammember`,
			name: "新增班组成员",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		delTeammember: {
			url: `${config.API_URL}/mes/cal/teammember/`,
			name: "删除班组成员",
			delete: async function (memberId) {
				return await http.delete(this.url + memberId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/cal/teammember/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "teammember");
			}
		}

	}

}
