import config from "@/config"
import http from "@/utils/request"

export default {
	flowGroup: {
		addGroup: {
			url: `${config.API_URL}/mes/workFlow/group`,
			name: "添加分组",
			post: async function (query) {
				return await http.post(this.url, query);
			}
		},
		groupLiset: {
			url: `${config.API_URL}/mes/workFlow/combinationGroup/listGroupWithProcess`,
			// url: `${process.env.VUE_APP_STATIC_BASEURL}/flyflow/api/combination/group/listGroupWithProcessMain`,
			name: "获取分组流程列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		delFlowGroup: {
			url: `${config.API_URL}/mes/workFlow/group/`,
			name: "删除流程分组",
			delete: async function (query) {
				return await http.delete(this.url+query);
			}
		},
	},
	process: {
		saveProcess: {
			url: `${config.API_URL}/mes/workFlow/process/save`,
			name: "保存系统流程信息",
			post: async function (query) {
				return await http.post(this.url, query);
			}
		},
	}
}
