import config from "@/config"

//系统路由
const routes = [
	{
		name: "layout",
		path: "/",
		component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
		redirect: config.DASHBOARD_URL || '/dashboard',
		children: []
	},
	{
		path: "/login",
		component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
		meta: {
			title: "登录"
		}
	},
	{
		path: "/workdataboard",
		component: () => import(/* webpackChunkName: "login" */ '@/views/screen/databoard/workdataboard/index'),
		meta: {
			title: "工单进度看板"
		}
	},
	{
		path: "/andonProportion",
		component: () => import(/* webpackChunkName: "login" */ '@/views/screen/databoard/andon/index'),
		meta: {
			title: "安灯异常看板"
		}
	},
	{
		path: "/inOutWarehouse",
		component: () => import(/* webpackChunkName: "login" */ '@/views/screen/databoard/inOutWarehouse/index'),
		meta: {
			title: "出入库任务"
		}
	},
	{
		path: "/user_register",
		component: () => import(/* webpackChunkName: "userRegister" */ '@/views/login/userRegister'),
		meta: {
			title: "注册"
		}
	},
	{
		path: "/reset_password",
		component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/login/resetPassword'),
		meta: {
			title: "重置密码"
		}
	},
	{
		name: "/i/chipworkshop",
		path: "/i/chipworkshop",
		component: () => import('@/views/screen/databoard/chipworkshop/index'),
		meta: {
			title: "智能车间大屏"
		}
	},
	{
		name: "/i/screen",
		path: "/i/screen",
		component: () => import('@/views/screen/product/monitor/index'),
		meta: {
			title: "生产智造数据大屏"
		}
	},
	{
		path: '/mes/md/mdItem/problemFeedback',
		component: () => import('@/views/scan/problemFeedback'),
		// hidden: true,
		meta: {
			title: "问题反馈"
		}
	},
	{
		path: "/scan",
		component: () => import(/* webpackChunkName: "login" */ '@/views/scan/index'),
		meta: {
			title: "登录"
		}
	},
]

export default routes;
