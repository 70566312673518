import config from "@/config"
import http from "@/utils/request"

export default {
	assetType: {
		listAssetType: {
			url: `${config.API_URL}/erp/asset/assetType/list`,
			name: "查询erp固定资产类型列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getAssetType: {
			url: `${config.API_URL}/erp/asset/assetType/`,
			name: "查询erp固定资产类型详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addAssetType: {
			url: `${config.API_URL}/erp/asset/assetType`,
			name: "新增erp固定资产类型",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateAssetType: {
			url: `${config.API_URL}/erp/asset/assetType`,
			name: "修改erp固定资产类型",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delAssetType: {
			url: `${config.API_URL}/erp/asset/assetType/`,
			name: "删除erp固定资产类型",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/asset/assetType/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`assetType`");
			}
		},
	},
	assets: {
		listAssets: {
			url: `${config.API_URL}/erp/asset/assets/list`,
			name: "查询erp固定资产列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getAssets: {
			url: `${config.API_URL}/erp/asset/assets/`,
			name: "查询erp固定资产详细",
			get: async function(assetId) {
				return await http.get(this.url +assetId);
			}
		},
		addAssets: {
			url: `${config.API_URL}/erp/asset/assets`,
			name: "新增erp固定资产",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateAssets: {
			url: `${config.API_URL}/erp/asset/assets`,
			name: "修改erp固定资产",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delAssets: {
			url: `${config.API_URL}/erp/asset/assets/`,
			name: "删除erp固定资产",
			delete: async function(assetId) {
				return await http.delete(this.url +assetId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/asset/assets/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`assets`");
			}
		},
	},
}
