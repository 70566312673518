import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import index from './index'
import i18n from './locales'
import router from './router'
import store from './store'
import App from './App.vue'
import "babel-polyfill"

/** 导入其他第三方组件 **/
import AnimatedNumber from 'animated-number-vue3'
import vue3SeamlessScroll from "vue3-seamless-scroll";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);
app.use(index);
app.use(AnimatedNumber);
app.use(vue3SeamlessScroll);

//挂载app
app.mount('#app');

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  }

  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
      callback = debounce(callback, 16);
      super(callback);
    }
  }
