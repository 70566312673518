import config from "@/config"
import http from "@/utils/request"

export default {
	token: {
		url: `${config.API_URL}/login`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	getCodeImg: {
		url: `${config.API_URL}/captchaImage`,
		name: "获取图片",
		get: async function(){
			return await http.get(this.url);
		},
		headers: {
			isToken: false
		},
		timeout: 20000
	},
	getInfo: {
		url: `${config.API_URL}/getInfo`,
		name: "获取登录权限",
		get: async function(){
			return await http.get(this.url);
		}
	},
	getRouters: {
		url: `${config.API_URL}/getRouters`,
		name: "获取菜单权限",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
	socialLogin: {
		url: `${config.API_URL}/socialLogin`,
		name: "第三方登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},
	logout: {
		url: `${config.API_URL}/logout`,
		name: "退出登录",
		post: async function(){
			return await http.post(this.url);
		}
	}
}
