import config from "@/config"
import http from "@/utils/request"

export default {
	dict: {
		listData: {
			url: `${config.API_URL}/system/dict/data/list`,
			name: "查询字典数据列表",
			get: async function(query){
				return await http.get(this.url,query);
			}
		},
		getData: {
			url: `${config.API_URL}/system/dict/data/`,
			name: "查询字典数据详细",
			get: async function(dictCode){
				return await http.get(this.url +dictCode);
			}
		},
		getDicts: {
			url: `${config.API_URL}/system/dict/data/type/`,
			name: "根据字典类型查询字典数据信息",
			get: async function(dictType){
				return await http.get(this.url+dictType);
			}
		},
		addData: {
			url: `${config.API_URL}/system/dict/data`,
			name: "新增字典数据",
			post: async function(data){
				return await http.post(this.url,data);
			}
		},
		updateData: {
			url: `${config.API_URL}/system/dict/data`,
			name: "新增字典数据",
			put: async function(data){
				return await http.put(this.url,data);
			}
		},
		delData: {
			url: `${config.API_URL}/system/dict/data/`,
			name: "删除字典数据",
			delete: async function(dictCode){
				return await http.delete(this.url +dictCode);
			}
		},
		listData1: {
			url: `${config.API_URL}/system/dict/data/list1`,
			name: "查询字典数据列表",
			get: async function(query){
				return await http.get(this.url,query);
			}
		},
	}
}
