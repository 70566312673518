import config from "@/config"
import http from "@/utils/request"

export default {
	menu: {
		myMenus: {
			url: `${config.API_URL}/system/menu/my/1.6.1`,
			name: "获取我的菜单",
			get: async function () {
				return await http.get(this.url);
			}
		},
		list: {
			url: `${config.API_URL}/system/menu/list`,
			name: "获取菜单",
			get: async function () {
				return await http.get(this.url);
			}
		}
	},
	dic: {
		tree: {
			url: `${config.API_URL}/system/dic/tree`,
			name: "获取字典树",
			get: async function () {
				return await http.get(this.url);
			}
		},
		list: {
			url: `${config.API_URL}/system/dic/list`,
			name: "字典明细",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		get: {
			url: `${config.API_URL}/system/dic/get`,
			name: "获取字典数据",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	role: {
		list: {
			url: `${config.API_URL}/system/role/list2`,
			name: "获取角色列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	dept: {
		list: {
			url: `${config.API_URL}/system/dept/list`,
			name: "获取部门列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	// user: {
	// 	getUserProfile: {
	// 		url: `${config.API_URL}/system/user/profile`,
	// 		name: "查询用户个人信息",
	// 		get: async function(params){
	// 			return await http.get(this.url, params);
	// 		}
	// 	}
	// },
	app: {
		list: {
			url: `${config.API_URL}/system/app/list`,
			name: "应用列表",
			get: async function () {
				return await http.get(this.url);
			}
		}
	},
	log: {
		list: {
			url: `${config.API_URL}/system/log/list`,
			name: "日志列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	operlog: {
		list: {
			url: `${config.API_URL}/monitor/operlog/list`,
			name: "日志列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	// 查询部门下拉树结构
	treeselect: {
		list: {
			url: `${config.API_URL}/system/dept/treeselect`,
			name: "查询部门下拉树结构",
			get: async function () {
				return await http.get(this.url);
			}
		}
	},
	// 查询用户列表
	user: {
		list: {
			url: `${config.API_URL}/system/user/list`,
			name: "获取用户列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		profile: {
			url: `${config.API_URL}/system/user/profile`,
			name: "获取用户属性",
			get: async function (params) {
				return await http.get(this.url, params);
			},
			put: async function (params) {
				return await http.put(this.url, params);
			}
		},
		updatePwd: {
			url: `${config.API_URL}/system/user/profile/updatePwd`,
			name: "修改用户密码",
			put: async function (params) {
				return await http.put(this.url, params);
			}
		},
		avatar: {
			url: `${config.API_URL}/system/user/profile/avatar`,
			name: "修改用户头像",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		},
		resetUserPwd: {
			url: `${config.API_URL}/system/user/resetPwd`,
			name: "重置用户密码",
			put: async function (userId, password) {
				const data = {
					userId,
					password
				  }
				return await http.put(this.url, data);
			}
		},
		getAuthRole: {
			url: `${config.API_URL}/system/user/authRole/`,
			name: "查询授权角色",
			get: async function (userId) {
				return await http.get(this.url + userId);
			}
		},
		updateAuthRole: {
			url: `${config.API_URL}/system/user/authRole`,
			name: "保存授权角色",
			put: async function (data) {
				return await http.put(this.url +'?userId='+data.userId+ '&roleIds='+data.roleIds, data);
			}
		},
		getUserProfile: {
			url: `${config.API_URL}/system/user/profile`,
			name: "查询用户个人信息",
			get: async function(params){
				return await http.get(this.url, params);
			}
		},
		uploadAvatar: {
			url: `${config.API_URL}/system/user/profile/avatar`,
			name: "用户头像上传",
			post: async function(params){
				return await http.post(this.url, params);
			}
		},
		getInfo: {
			url: `${config.API_URL}/getInfo`,
			name: "获取用户详细信息",
			get: async function(params){
				return await http.get(this.url, params);
			}
		}
	},
	// 用户状态修改
	changeUserStatus: {
		status1: {
			url: `${config.API_URL}/system/user/changeStatus`,
			name: "用户状态修改",
			put: async function (params) {
				return await http.put(this.url, params);
			}
		}
	},
	// 查询用户详细
	getUser: {
		list: {
			url: `${config.API_URL}/system/user/`,
			name: "用户状态修改",
			get: async function (params) {
				if (!params || params == "undefined" || params == "null") {
					params = "";
				}
				return await http.get(this.url + params);
			}
		}
	},
	// 修改用户
	updateUser: {
		list: {
			url: `${config.API_URL}/system/user`,
			name: "修改用户",
			put: async function (params) {
				return await http.put(this.url, params);
			}
		}
	},
	// 新增用户
	addUser: {
		list: {
			url: `${config.API_URL}/system/user`,
			name: "新增用户",
			post: async function (params) {
				return await http.post(this.url, params);
			}
		}
	},
	// 删除用户
	delUser: {
		list: {
			url: `${config.API_URL}/system/user/`,
			name: "删除用户",
			delete: async function (params) {
				return await http.delete(this.url + params, params);
			}
		}
	},
	table: {
		list: {
			url: `${config.API_URL}/system/table/list`,
			name: "表格列管理列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		info: {
			url: `${config.API_URL}/system/table/info`,
			name: "表格列管理详情",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	tasks: {
		list: {
			url: `${config.API_URL}/system/tasks/list`,
			name: "系统任务管理",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	exportFile: {
		url: `${config.API_URL}/system/user/export`,
		name: "导出附件",
		post: async function (data, config = {}) {
			// config = {
			// 	headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			// 	responseType: 'blob'
			//   }
			return await http.postDom(this.url, data, config, "user");
		}
	},
	message: {
		list: {
			url: `${config.API_URL}/system/message/list`,
			name: "查询消息列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		},
		info: {
			url: `${config.API_URL}/system/message/`,
			name: "获取消息详细信息",
			get: async function (params) {
				return await http.get(this.url + params);
			}
		},
		update: {
			url: `${config.API_URL}/system/message/`,
			name: "修改消息",
			put: async function (params) {
				return await http.put(this.url, params);
			}
		}
	},
	appVersionManager: {
		list: {
			url: `${config.API_URL}/system/appVersionManager/list`,
			name: "查询平台app版本管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		get: {
			url: `${config.API_URL}/system/appVersionManager/`,
			name: "查询平台app版本管理详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		add: {
			url: `${config.API_URL}/system/appVersionManager`,
			name: "新增平台app版本管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		update: {
			url: `${config.API_URL}/system/appVersionManager`,
			name: "修改平台app版本管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		del: {
			url: `${config.API_URL}/system/appVersionManager/`,
			name: "删除平台app版本管理",
			delete: async function(id) {
				return await http.delete(this.url + id);
			}
		},
		export: {
			url: `${config.API_URL}/system/appVersionManager/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "appVersionManager");
			}
		}
	},
	// 第三方用户
	thirdPartyUser: {
		// 列表
		list: {
			url: `${config.API_URL}/system/user/thirdParty`,
			name: "获取用户列表",
			get: async function (params) {
				return await http.get(this.url, params);
			}
		}
	},
	trademarkManagement:{
		listTrademarkManagement: {
			url: `${config.API_URL}/system/trademarkManagement/list`,
			name: "查询商标管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getTrademarkManagement: {
			url: `${config.API_URL}/system/trademarkManagement/`,
			name: "查询商标管理详细",
			get: async function(trademarkManagementId) {
				return await http.get(this.url +trademarkManagementId);
			}
		},
		addTrademarkManagement: {
			url: `${config.API_URL}/system/trademarkManagement`,
			name: "新增商标管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateTrademarkManagement: {
			url: `${config.API_URL}/system/trademarkManagement`,
			name: "修改商标管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delTrademarkManagement: {
			url: `${config.API_URL}/system/trademarkManagement/`,
			name: "删除商标管理",
			delete: async function(trademarkManagementId) {
				return await http.delete(this.url +trademarkManagementId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/system/trademarkManagement/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`TrademarkManagement`");
			}
		},
	}
}
