import config from "@/config"
import http from "@/utils/request"

export default {
	gen: {
		listTable: {
			url: `${config.API_URL}/tool/gen/list`,
			name: "查询生成表数据",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listDbTable: {
			url: `${config.API_URL}/tool/gen/db/list`,
			name: "查询db数据库列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getGenTable: {
			url: `${config.API_URL}/tool/gen/`,
			name: "查询代码信息",
			get: async function (tableId) {
				return await http.get(this.url + tableId);
			}
		},
		updateGenTable: {
			url: `${config.API_URL}/tool/gen/`,
			name: "修改代码生成信息",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		importTable: {
			url: `${config.API_URL}/tool/gen/importTable`,
			name: "导入表",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		previewTable: {
			url: `${config.API_URL}/tool/gen/preview/`,
			name: "预览生成代码",
			get: async function (tableId) {
				return await http.get(this.url + tableId);
			}
		},
		delTable: {
			url: `${config.API_URL}/tool/gen/`,
			name: "删除表数据",
			delete: async function (tableId) {
				return await http.delete(this.url + tableId);
			}
		},
		genCode: {
			url: `${config.API_URL}/tool/gen/genCode/`,
			name: "生成代码（自定义路径）",
			get: async function (tableName) {
				return await http.get(this.url + tableName);
			}
		},
		synchDb: {
			url: `${config.API_URL}/tool/gen/synchDb/`,
			name: "同步数据库",
			get: async function (tableName) {
				return await http.get(this.url + tableName);
			}
		},
		downloadCode: {
			url: `${config.API_URL}/tool/gen/batchGenCode?tables=`,
			name: "下载生成代码",
			get: async function (data, config = {}) {
				return await http.downloadZip(this.url + data.tables, {}, config, data.fileName);
			}
		},
	}
}
