/**
 * @description 自动import导入所有 api 模块
 */

const files = require.context('./model', false, /\.js$/)
const filesErp = require.context('./erp', false, /\.js$/)
const modules = {}
const modulesErp = {}
let modulesAll = {}
files.keys().forEach((key) => {
	modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
filesErp.keys().forEach((key) => {
	modulesErp[key.replace(/(\.\/|\.js)/g, '')] = filesErp(key).default
})
modulesAll = {...modules,...modulesErp}
export default modulesAll
