import config from "@/config"
import http from "@/utils/request"

export default {
	// 员工档案
	StaffRecord: {
		listStaffRecord: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/list`,
			name: "查询员工档案列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listStaffSalaryMont: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/listStaffSalaryMont`,
			name: "查询员工档案列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getStaffRecord: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/`,
			name: "查询员工档案详细",
			get: async function (staffRecordId) {
				return await http.get(this.url + staffRecordId);
			}
		},
		getByEmployeeNumber: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/getByEmployeeNumber/`,
			name: "查询员工档案详细",
			get: async function (employeeNumber) {
				return await http.get(this.url + employeeNumber);
			}
		},
		addStaffRecord: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord`,
			name: "新增员工档案",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateStaffRecord: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord`,
			name: "修改员工档案",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delStaffRecord: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/`,
			name: "删除员工档案",
			delete: async function (staffRecordId) {
				return await http.delete(this.url + staffRecordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "staffRecord");
			}
		},
		// 计算绩效奖金
		performanceBonus: {
			url: `${config.API_URL}/erp/salaryManagement/staffRecord/performanceBonus`,
			name: "获取绩效奖金",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
	},
	/*薪酬方案*/
	compensationPlan:{
		listCompensationPlan: {
			url: `${config.API_URL}/erp/ErpCompensationPlan/compensationPlan/list`,
			name: "查询薪酬方案列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getCompensationPlan: {
			url: `${config.API_URL}/erp/ErpCompensationPlan/compensationPlan/`,
			name: "查询薪酬方案详细",
			get: async function(compensationPlanId) {
				return await http.get(this.url +compensationPlanId);
			}
		},
		addCompensationPlan: {
			url: `${config.API_URL}/erp/ErpCompensationPlan/compensationPlan`,
			name: "新增薪酬方案",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateCompensationPlan: {
			url: `${config.API_URL}/erp/ErpCompensationPlan/compensationPlan`,
			name: "修改薪酬方案",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delCompensationPlan: {
			url: `${config.API_URL}/erp/ErpCompensationPlan/compensationPlan/`,
			name: "删除薪酬方案",
			delete: async function(compensationPlanId) {
				return await http.delete(this.url +compensationPlanId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/ErpCompensationPlan/compensationPlan/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "compensationPlan");
			}
		},
	},
	/**
	 * 提成规则
	 */
	royaltyRule:{
		listRoyaltyRule: {
			url: `${config.API_URL}/erp/ErpRoyaltyRule/royaltyRule/list`,
			name: "查询提成规则列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getRoyaltyRule: {
			url: `${config.API_URL}/erp/ErpRoyaltyRule/royaltyRule/`,
			name: "查询提成规则详细",
			get: async function(royaltyRuleId) {
				return await http.get(this.url +royaltyRuleId);
			}
		},
		addRoyaltyRule: {
			url: `${config.API_URL}/erp/ErpRoyaltyRule/royaltyRule`,
			name: "新增提成规则",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateRoyaltyRule: {
			url: `${config.API_URL}/erp/ErpRoyaltyRule/royaltyRule`,
			name: "修改提成规则",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delRoyaltyRule: {
			url: `${config.API_URL}/erp/ErpRoyaltyRule/royaltyRule/`,
			name: "删除提成规则",
			delete: async function(royaltyRuleId) {
				return await http.delete(this.url +royaltyRuleId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/ErpRoyaltyRule/royaltyRule/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "royaltyRule");
			}
		},
	},
	// 考勤
	checkingIn:{
		listCheckingIn: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn/list`,
			name: "查询考勤列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getCheckingIn: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn/`,
			name: "查询考勤详细",
			get: async function(checkingInId) {
				return await http.get(this.url +checkingInId);
			}
		},
		addCheckingIn: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn`,
			name: "新增考勤",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateCheckingIn: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn`,
			name: "修改考勤",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delCheckingIn: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn/`,
			name: "删除考勤",
			delete: async function(checkingInId) {
				return await http.delete(this.url +checkingInId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "checkingIn");
			}
		},
		importTemplate: {
			url: `${config.API_URL}/erp/ErpCheckingIn/checkingIn/importTemplate`,
			name: "下载模板",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "checkingIn");
			}
		},
	},
	// 工资明细查询
	salaryDetailqQuery:{
		listSalaryDetailqQuery: {
			url: `${config.API_URL}/erp/ErpSalaryDetailQuery/salaryDetailqQuery/list`,
			name: "查询工资明细查询列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/ErpSalaryDetailQuery/salaryDetailqQuery/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "salaryDetailqQuery");
			}
		},
	},
	// 工资表
	salary:{
		listSalary: {
			url: `${config.API_URL}/erp/salaryManagement/salary/list`,
			name: "查询工资基础列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getSalary: {
			url: `${config.API_URL}/erp/salaryManagement/salary/`,
			name: "查询工资基础详细",
			get: async function(salaryId) {
				return await http.get(this.url +salaryId);
			}
		},
		addSalary: {
			url: `${config.API_URL}/erp/salaryManagement/salary`,
			name: "新增工资基础",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateSalary: {
			url: `${config.API_URL}/erp/salaryManagement/salary`,
			name: "修改工资基础",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delSalary: {
			url: `${config.API_URL}/erp/salaryManagement/salary/`,
			name: "删除工资基础",
			delete: async function(salaryId) {
				return await http.delete(this.url +salaryId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/salaryManagement/salary/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "salary");
			}
		},
		approve: {
			url: `${config.API_URL}/erp/salaryManagement/salary/approve/`,
			name: "审批通过",
			get: async function(salaryId) {
				return await http.get(this.url +salaryId);
			}
		},
		reject: {
			url: `${config.API_URL}/erp/salaryManagement/salary/reject/`,
			name: "审批不通过",
			get: async function(salaryId) {
				return await http.get(this.url +salaryId);
			}
		},
		salaryIssue: {
			url: `${config.API_URL}/erp/salaryManagement/salary/salaryIssue/`,
			name: "发放工资",
			get: async function(salaryId) {
				return await http.get(this.url +salaryId);
			}
		},
		submitSalaryApprove: {
			url: `${config.API_URL}/erp/salaryManagement/salary/submitSalaryApprove`,
			name: "提交审批",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
	},
	// 工资明细
	salaryDetail:{
		listSalaryDetail: {
			url: `${config.API_URL}erp/salaryManagement/salaryDetail/list`,
			name: "查询工资明细列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getSalaryDetail: {
			url: `${config.API_URL}erp/salaryManagement/salaryDetail/`,
			name: "查询工资明细详细",
			get: async function(salaryDetailId) {
				return await http.get(this.url +salaryDetailId);
			}
		},
		addSalaryDetail: {
			url: `${config.API_URL}erp/salaryManagement/salaryDetail`,
			name: "新增工资明细",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateSalaryDetail: {
			url: `${config.API_URL}erp/salaryManagement/salaryDetail`,
			name: "修改工资明细",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delSalaryDetail: {
			url: `${config.API_URL}erp/salaryManagement/salaryDetail/`,
			name: "删除工资明细",
			delete: async function(salaryDetailId) {
				return await http.delete(this.url +salaryDetailId);
			}
		},
		exportFile: {
			url: `${config.API_URL}erp/salaryManagement/salaryDetail/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`salaryDetail`");
			}
		},
	},
}
