import config from "@/config"
import http from "@/utils/request"

export default {
	tool: {
		listTool: {
			url: `${config.API_URL}/mes/tm/tool/list`,
			name: "查询工装夹具清单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getTool: {
			url: `${config.API_URL}/mes/tm/tool/`,
			name: "查询工装夹具清单详细",
			get: async function (toolId) {
				return await http.get(this.url + toolId);
			}
		},
		addTool: {
			url: `${config.API_URL}/mes/tm/tool`,
			name: "新增工装夹具清单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateTool: {
			url: `${config.API_URL}/mes/tm/tool`,
			name: "修改工装夹具清单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delTool: {
			url: `${config.API_URL}/mes/tm/tool/`,
			name: "删除工装夹具清单",
			delete: async function (toolId) {
				return await http.delete(this.url + toolId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/tm/tool/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "tool");
			}
		}
	},
	tooltype: {
		listType: {
			url: `${config.API_URL}/mes/tm/tooltype/list`,
			name: "查询工装夹具类型列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listAllTooltype: {
			url: `${config.API_URL}/mes/tm/tooltype/listAll`,
			name: "查询所有工装夹具类型",
			get: async function () {
				return await http.get(this.url);
			}
		},
		getType: {
			url: `${config.API_URL}/mes/tm/tooltype/`,
			name: "查询工装夹具类型详细",
			get: async function (toolTypeId) {
				return await http.get(this.url + toolTypeId);
			}
		},
		addType: {
			url: `${config.API_URL}/mes/tm/tooltype`,
			name: "新增工装夹具类型",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateType: {
			url: `${config.API_URL}/mes/tm/tooltype`,
			name: "修改工装夹具类型",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delType: {
			url: `${config.API_URL}/mes/tm/tooltype/`,
			name: "删除工装夹具类型",
			delete: async function (toolTypeId) {
				return await http.delete(this.url + toolTypeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/tm/tooltype/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "tooltype");
			}
		}
	}
}
