import config from "@/config"
import http from "@/utils/request"

export default {
	incomeExpenseType: {
		listIncomeExpenseType: {
			url: `${config.API_URL}/erp/finance/incomeExpenseType/list`,
			name: "查询erp收支类型列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getIncomeExpenseType: {
			url: `${config.API_URL}/erp/finance/incomeExpenseType/`,
			name: "查询erp收支类型详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addIncomeExpenseType: {
			url: `${config.API_URL}/erp/finance/incomeExpenseType`,
			name: "新增erp收支类型",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateIncomeExpenseType: {
			url: `${config.API_URL}/erp/finance/incomeExpenseType`,
			name: "修改erp收支类型",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delIncomeExpenseType: {
			url: `${config.API_URL}/erp/finance/incomeExpenseType/`,
			name: "删除erp收支类型",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/finance/incomeExpenseType/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`incomeExpenseType`");
			}
		},
	},
	income: {
		listIncome: {
			url: `${config.API_URL}/erp/finance/income/list`,
			name: "查询erp收入管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getIncome: {
			url: `${config.API_URL}/erp/finance/income/`,
			name: "查询erp收入管理详细",
			get: async function(incomeId) {
				return await http.get(this.url +incomeId);
			}
		},
		addIncome: {
			url: `${config.API_URL}/erp/finance/income`,
			name: "新增erp收入管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateIncome: {
			url: `${config.API_URL}/erp/finance/income`,
			name: "修改erp收入管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delIncome: {
			url: `${config.API_URL}/erp/finance/income/`,
			name: "删除erp收入管理",
			delete: async function(incomeId) {
				return await http.delete(this.url +incomeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/finance/income/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`income`");
			}
		},
	},
	expense: {
		listExpense: {
			url: `${config.API_URL}/erp/finance/expense/list`,
			name: "查询erp支出管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getExpense: {
			url: `${config.API_URL}/erp/finance/expense/`,
			name: "查询erp支出管理详细",
			get: async function(expenseId) {
				return await http.get(this.url +expenseId);
			}
		},
		addExpense: {
			url: `${config.API_URL}/erp/finance/expense`,
			name: "新增erp支出管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateExpense: {
			url: `${config.API_URL}/erp/finance/expense`,
			name: "修改erp支出管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delExpense: {
			url: `${config.API_URL}/erp/finance/expense/`,
			name: "删除erp支出管理",
			delete: async function(expenseId) {
				return await http.delete(this.url +expenseId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/finance/expense/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`expense`");
			}
		},
	},
	invoice: {
		listInvoice: {
			url: `${config.API_URL}/erp/finance/invoice/list`,
			name: "查询erp开票管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getInvoice: {
			url: `${config.API_URL}/erp/finance/invoice/`,
			name: "查询erp开票管理详细",
			get: async function(invoiceId) {
				return await http.get(this.url +invoiceId);
			}
		},
		addInvoice: {
			url: `${config.API_URL}/erp/finance/invoice`,
			name: "新增erp开票管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateInvoice: {
			url: `${config.API_URL}/erp/finance/invoice`,
			name: "修改erp开票管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delInvoice: {
			url: `${config.API_URL}/erp/finance/invoice/`,
			name: "删除erp开票管理",
			delete: async function(invoiceId) {
				return await http.delete(this.url +invoiceId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/finance/invoice/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`invoice`");
			}
		},

	},
	receivingInvoice: {
		listReceivingInvoice: {
			url: `${config.API_URL}/erp/finance/receivingInvoice/list`,
			name: "查询erp到票列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getReceivingInvoice: {
			url: `${config.API_URL}/erp/finance/receivingInvoice/`,
			name: "查询erp到票详细",
			get: async function(receivingInvoiceId) {
				return await http.get(this.url +receivingInvoiceId);
			}
		},
		addReceivingInvoice: {
			url: `${config.API_URL}/erp/finance/receivingInvoice`,
			name: "新增erp到票",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateReceivingInvoice: {
			url: `${config.API_URL}/erp/finance/receivingInvoice`,
			name: "修改erp到票",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delReceivingInvoice: {
			url: `${config.API_URL}/erp/finance/receivingInvoice/`,
			name: "删除erp到票",
			delete: async function(receivingInvoiceId) {
				return await http.delete(this.url +receivingInvoiceId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/finance/receivingInvoice/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`receivingInvoice`");
			}
		},
	}
}
