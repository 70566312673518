import config from "@/config"
import http from "@/utils/request"

export default {
  listLog: {
    url: `${config.API_URL}/monitor/operlog/list`,
    name: "查询操作日志记录列表",
    get: async function(data) {
      return await http.get(this.url, data);
    }
  },
  getLog: {
    url: `${config.API_URL}/operlog/`,
    name: "查询操作日志记录详细",
    get: async function(operId) {
      return await http.get(this.url +operId);
    }
  },
  addLog: {
    url: `${config.API_URL}/monitor/operlog`,
    name: "新增操作日志记录",
    post: async function(data) {
      return await http.post(this.url ,data);
    }
  },
  updateLog: {
    url: `${config.API_URL}/monitor/operlog`,
    name: "修改操作日志记录",
    put: async function(data) {
      return await http.put(this.url ,data);
    }
  },
  delLog: {
    url: `${config.API_URL}/monitor/operlog/`,
    name: "删除操作日志记录",
    delete: async function(operId) {
      return await http.delete(this.url +operId);
    }
  },
  exportFile1: {
		url: `${config.API_URL}/monitor/operlog/export1`,
		name: "导出附件",
		get: async function(data, config={}){
			return await http.get(this.url, data, config);
		}
	},
  echarts: {
		url: `${config.API_URL}/monitor/operlog/echarts`,
		name: "操作日志柱状图",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
}
