import config from "@/config"
import http from "@/utils/request"

export default {
	problemType:{
		listType: {
			url: `${config.API_URL}/erp/problem/type/list`,
			name: "查询问题类型列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getType: {
			url: `${config.API_URL}/erp/problem/type/`,
			name: "查询问题类型详细",
			get: async function(problemTypeId) {
				return await http.get(this.url +problemTypeId);
			}
		},
		addType: {
			url: `${config.API_URL}/erp/problem/type`,
			name: "新增问题类型",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateType: {
			url: `${config.API_URL}/erp/problem/type`,
			name: "修改问题类型",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delType: {
			url: `${config.API_URL}/erp/problem/type/`,
			name: "删除问题类型",
			delete: async function(problemTypeId) {
				return await http.delete(this.url +problemTypeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/problem/type/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "problemType");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/problem/type/changeStatus`,
			name: "问题类型状态修改",
			put: async function(problemTypeId, status){
				const data = {
					problemTypeId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	problemFeedback:{
		listProblemFeedback: {
			url: `${config.API_URL}/erp/problem/problemFeedback/list`,
			name: "查询问题反馈列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getProblemFeedback: {
			url: `${config.API_URL}/erp/problem/problemFeedback/`,
			name: "查询问题反馈详细",
			get: async function(problemFeedbackId) {
				return await http.get(this.url +problemFeedbackId);
			}
		},
		addProblemFeedback: {
			url: `${config.API_URL}/erp/problem/problemFeedback`,
			name: "新增问题反馈",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateProblemFeedback: {
			url: `${config.API_URL}/erp/problem/problemFeedback`,
			name: "修改问题反馈",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delProblemFeedback: {
			url: `${config.API_URL}/erp/problem/problemFeedback/`,
			name: "删除问题反馈",
			delete: async function(problemFeedbackId) {
				return await http.delete(this.url +problemFeedbackId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/problem/problemFeedback/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "problemFeedback");
			}
		},
	}
}
