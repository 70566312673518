import config from "@/config"
import http from "@/utils/request"

export default {
	approval: {
		queryTodoTaskList: {
			url: `${config.API_URL}/erp/approval/queryTodoTaskList`,
			name: "查询当前登录用户的待办任务",
			get: async function(data) {
				return await http.get(this.url ,data);
			}
		},
	},
}
