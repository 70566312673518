import config from "@/config"
import http from "@/utils/request"
export default {
  listMenu:{
    url: `${config.API_URL}/system/menu/list`,
    name: "查询菜单列表",
    get: async function(query){
      return await http.get(this.url,query);
    }
  },
  getMenu:{
    url: `${config.API_URL}/system/menu/`,
    name: "查询菜单详细",
    get: async function(menuId){
      return await http.get(this.url+menuId);
    }
  },
  treeselect:{
    url: `${config.API_URL}/system/menu/treeselect`,
    name: "查询菜单下拉树结构",
    get: async function(data){
      return await http.get(this.url,data);
    }
  },
	treeselectList:{
    url: `${config.API_URL}/system/menu/treeselectList`,
    name: "查询菜单下拉树",
    get: async function(data){
      return await http.get(this.url,data);
    }
  },
  roleMenuTreeselect:{
    url: `${config.API_URL}/system/menu/roleMenuTreeselect/`,
    name: "根据角色ID查询菜单下拉树结构",
    get: async function(roleId){
      return await http.get(this.url+roleId);
    }
  },
	ureportFileMenuTreeselect:{
		url: `${config.API_URL}/system/menu/ureportFileMenuTreeselect/`,
		name: "根据报表查询菜单下拉树结构",
		get: async function(id){
			return await http.get(this.url+id);
		}
	},
  addMenu:{
    url: `${config.API_URL}/system/menu`,
    name: "新增菜单",
    post: async function(data){
      return await http.post(this.url,data);
    }
  },
  updateMenu:{
    url: `${config.API_URL}/system/menu`,
    name: "修改菜单",
    put: async function(data){
      return await http.put(this.url,data);
    }
  },
  delMenu:{
    url: `${config.API_URL}/system/menu/`,
    name: "修改菜单",
    delete: async function(menuId){
      return await http.delete(this.url+menuId,menuId);
    }
  }
}
