import config from "@/config"
import http from "@/utils/request"

export default {
	defectrecord: {
		listDefectrecord: {
			url: `${config.API_URL}/mes/qc/defectrecord/list`,
			name: "查询检验单缺陷记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getDefectrecord: {
			url: `${config.API_URL}/mes/qc/defectrecord/`,
			name: "查询检验单缺陷记录详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addDefectrecord: {
			url: `${config.API_URL}/mes/qc/defectrecord`,
			name: "新增检验单缺陷记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateDefectrecord: {
			url: `${config.API_URL}/mes/qc/defectrecord`,
			name: "修改检验单缺陷记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delDefectrecord: {
			url: `${config.API_URL}/mes/qc/defectrecord/`,
			name: "删除检验单缺陷记录",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/defectrecord/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "defectrecord");
			}
		}
	},
	ipqc: {
		listIpqc: {
			url: `${config.API_URL}/mes/qc/ipqc/list`,
			name: "查询过程检验单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		feedBackList: {
			url: `${config.API_URL}/mes/qc/ipqc/feedBackList`,
			name: "查询过程检验单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIpqc: {
			url: `${config.API_URL}/mes/qc/ipqc/`,
			name: "查询过程检验单详细",
			get: async function (ipqcId) {
				return await http.get(this.url + ipqcId);
			}
		},
		addIpqc: {
			url: `${config.API_URL}/mes/qc/ipqc`,
			name: "新增过程检验单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIpqc: {
			url: `${config.API_URL}/mes/qc/ipqc`,
			name: "修改过程检验单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIpqc: {
			url: `${config.API_URL}/mes/qc/ipqc/`,
			name: "删除过程检验单",
			delete: async function (ipqcId) {
				return await http.delete(this.url + ipqcId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/ipqc/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "ipqc");
			}
		},
		submitInspect: {
			url: `${config.API_URL}/mes/qc/ipqc/submitInspect`,
			name: "提交报工质检",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		exportInspectFile: {
			url: `${config.API_URL}/mes/qc/ipqc/exportInspect`,
			name: "导出报工质检检验单",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "ipqc_inspect");
			}
		},
	},
	lqc: {
		listLqc: {
			url: `${config.API_URL}/mes/qc/lqc/list`,
			name: "查询实验室检验单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getLqc: {
			url: `${config.API_URL}/mes/qc/lqc/`,
			name: "查询实验室检验单详细",
			get: async function (lqcId) {
				return await http.get(this.url + lqcId);
			}
		},
		addLqc: {
			url: `${config.API_URL}/mes/qc/lqc`,
			name: "新增实验室检验单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateLqc: {
			url: `${config.API_URL}/mes/qc/lqc`,
			name: "修改实验室检验单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delLqc: {
			url: `${config.API_URL}/mes/qc/lqc/`,
			name: "删除实验室检验单",
			delete: async function (lqcId) {
				return await http.delete(this.url + lqcId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/lqc/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "lqc");
			}
		}
	},
	ipqcline: {
		listIpqcline: {
			url: `${config.API_URL}/mes/qc/ipqcline/list`,
			name: "查询过程检验单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIpqcline: {
			url: `${config.API_URL}/mes/qc/ipqcline/`,
			name: "查询过程检验单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addIpqcline: {
			url: `${config.API_URL}/mes/qc/ipqcline`,
			name: "新增过程检验单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIpqcline: {
			url: `${config.API_URL}/mes/qc/ipqcline`,
			name: "修改过程检验单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIpqcline: {
			url: `${config.API_URL}/mes/qc/ipqcline/`,
			name: "删除过程检验单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/ipqcline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "ipqcline");
			}
		}
	},
	lqcline: {
		listLqcline: {
			url: `${config.API_URL}/mes/qc/lqcline/list`,
			name: "查询实验室检验单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getLqcline: {
			url: `${config.API_URL}/mes/qc/lqcline/`,
			name: "查询实验室检验单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addLqcline: {
			url: `${config.API_URL}/mes/qc/lqcline`,
			name: "新增实验室检验单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateLqcline: {
			url: `${config.API_URL}/mes/qc/lqcline`,
			name: "修改实验室检验单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delLqcline: {
			url: `${config.API_URL}/mes/qc/lqcline/`,
			name: "删除实验室检验单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/lqcline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "lqcline");
			}
		}
	},
	iqc: {
		listIqc: {
			url: `${config.API_URL}/mes/qc/iqc/list`,
			name: "查询来料检验单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listRecptIqc: {
			url: `${config.API_URL}/mes/qc/iqc/listRecptIqc`,
			name: "查询来料检验单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIqc: {
			url: `${config.API_URL}/mes/qc/iqc/`,
			name: "查询来料检验单详细",
			get: async function (iqcId) {
				return await http.get(this.url + iqcId);
			}
		},
		addIqc: {
			url: `${config.API_URL}/mes/qc/iqc`,
			name: "新增来料检验单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIqc: {
			url: `${config.API_URL}/mes/qc/iqc`,
			name: "修改来料检验单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIqc: {
			url: `${config.API_URL}/mes/qc/iqc/`,
			name: "删除来料检验单",
			delete: async function (iqcId) {
				return await http.delete(this.url + iqcId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/iqc/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "iqc");
			}
		}
	},
	iqcline: {
		listIqcline: {
			url: `${config.API_URL}/mes/qc/iqcline/list`,
			name: "查询来料检验单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIqcline: {
			url: `${config.API_URL}/mes/qc/iqcline/`,
			name: "查询来料检验单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addIqcline: {
			url: `${config.API_URL}/mes/qc/iqcline`,
			name: "新增来料检验单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIqcline: {
			url: `${config.API_URL}/mes/qc/iqcline`,
			name: "修改来料检验单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIqcline: {
			url: `${config.API_URL}/mes/qc/iqcline/`,
			name: "删除来料检验单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/iqcline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "iqcline");
			}
		}
	},
	oqc: {
		listOqc: {
			url: `${config.API_URL}/mes/qc/oqc/list`,
			name: "查询出货检验单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getOqc: {
			url: `${config.API_URL}/mes/qc/oqc/`,
			name: "查询出货检验单详细",
			get: async function (oqcId) {
				return await http.get(this.url + oqcId);
			}
		},
		addOqc: {
			url: `${config.API_URL}/mes/qc/oqc`,
			name: "新增出货检验单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateOqc: {
			url: `${config.API_URL}/mes/qc/oqc`,
			name: "修改出货检验单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delOqc: {
			url: `${config.API_URL}/mes/qc/oqc/`,
			name: "删除出货检验单",
			delete: async function (oqcId) {
				return await http.delete(this.url + oqcId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/oqc/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "oqc");
			}
		}
	},
	oqcline: {
		listOqcline: {
			url: `${config.API_URL}/mes/qc/oqcline/list`,
			name: "查询出货检验单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getOqcline: {
			url: `${config.API_URL}/mes/qc/oqcline/`,
			name: "查询出货检验单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addOqcline: {
			url: `${config.API_URL}/mes/qc/oqcline`,
			name: "新增出货检验单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateOqcline: {
			url: `${config.API_URL}/mes/qc/oqcline`,
			name: "修改出货检验单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delOqcline: {
			url: `${config.API_URL}/mes/qc/oqcline/`,
			name: "删除出货检验单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/oqcline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "oqcline");
			}
		}
	},
	qcdefect: {
		listQcdefect: {
			url: `${config.API_URL}/mes/qc/qcdefect/list`,
			name: "查询常见缺陷列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getQcdefect: {
			url: `${config.API_URL}/mes/qc/qcdefect/`,
			name: "查询常见缺陷详细",
			get: async function (defectId) {
				return await http.get(this.url + defectId);
			}
		},
		addQcdefect: {
			url: `${config.API_URL}/mes/qc/qcdefect`,
			name: "新增常见缺陷",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateQcdefect: {
			url: `${config.API_URL}/mes/qc/qcdefect`,
			name: "修改常见缺陷",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delQcdefect: {
			url: `${config.API_URL}/mes/qc/qcdefect/`,
			name: "删除常见缺陷",
			delete: async function (defectId) {
				return await http.delete(this.url + defectId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/qcdefect/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "qcdefect");
			}
		}
	},
	qcindex: {
		listQcindex: {
			url: `${config.API_URL}/mes/qc/qcindex/list`,
			name: "查询检测项列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getQcindex: {
			url: `${config.API_URL}/mes/qc/qcindex/`,
			name: "查询检测项详细",
			get: async function (indexId) {
				return await http.get(this.url + indexId);
			}
		},
		addQcindex: {
			url: `${config.API_URL}/mes/qc/qcindex`,
			name: "新增检测项",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateQcindex: {
			url: `${config.API_URL}/mes/qc/qcindex`,
			name: "修改检测项",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delQcindex: {
			url: `${config.API_URL}/mes/qc/qcindex/`,
			name: "删除检测项",
			delete: async function (indexId) {
				return await http.delete(this.url + indexId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/qcindex/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "qcindex");
			}
		}
	},
	qctemplate: {
		listQctemplate: {
			url: `${config.API_URL}/mes/qc/qctemplate/list`,
			name: "查询检测模板列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getQctemplate: {
			url: `${config.API_URL}/mes/qc/qctemplate/`,
			name: "查询检测模板详细",
			get: async function (templateId) {
				return await http.get(this.url + templateId);
			}
		},
		addQctemplate: {
			url: `${config.API_URL}/mes/qc/qctemplate`,
			name: "新增检测模板",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateQctemplate: {
			url: `${config.API_URL}/mes/qc/qctemplate`,
			name: "修改检测模板",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delQctemplate: {
			url: `${config.API_URL}/mes/qc/qctemplate/`,
			name: "删除检测模板",
			delete: async function (templateId) {
				return await http.delete(this.url + templateId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/qctemplate/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "qctemplate");
			}
		}
	},
	templateindex: {
		listTemplateindex: {
			url: `${config.API_URL}/mes/qc/templateindex/list`,
			name: "查询检测模板-检测项列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getTemplateindex: {
			url: `${config.API_URL}/mes/qc/templateindex/`,
			name: "查询检测模板-检测项详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addTemplateindex: {
			url: `${config.API_URL}/mes/qc/templateindex`,
			name: "新增检测模板-检测项",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateTemplateindex: {
			url: `${config.API_URL}/mes/qc/templateindex`,
			name: "修改检测模板-检测项",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delTemplateindex: {
			url: `${config.API_URL}/mes/qc/templateindex/`,
			name: "删除检测模板-检测项",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/templateindex/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "templateindex");
			}
		}
	},
	templateproduct: {
		listTemplateproduct: {
			url: `${config.API_URL}/mes/qc/templateproduct/list`,
			name: "查询检测模板-产品列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getTemplateproduct: {
			url: `${config.API_URL}/mes/qc/templateproduct/`,
			name: "查询检测模板-产品详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addTemplateproduct: {
			url: `${config.API_URL}/mes/qc/templateproduct`,
			name: "新增检测模板-产品",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateTemplateproduct: {
			url: `${config.API_URL}/mes/qc/templateproduct`,
			name: "修改检测模板-产品",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delTemplateproduct: {
			url: `${config.API_URL}/mes/qc/templateproduct/`,
			name: "删除检测模板-产品",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/qc/templateproduct/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "templateproduct");
			}
		}
	},
	DefectConfig: {
		listDefectConfig: {
			url: `${config.API_URL}/mes/qc/defectConfig/list`,
			name: "查询缺陷配置列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getDefectConfig: {
			url: `${config.API_URL}/mes/qc/defectConfig/`,
			name: "查询缺陷配置详细",
			get: async function(defectConfigId) {
				return await http.get(this.url +defectConfigId);
			}
		},
		addDefectConfig: {
			url: `${config.API_URL}/mes/qc/defectConfig`,
			name: "新增缺陷配置",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateDefectConfig: {
			url: `${config.API_URL}/mes/qc/defectConfig`,
			name: "修改缺陷配置",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delDefectConfig: {
			url: `${config.API_URL}/mes/qc/defectConfig/`,
			name: "删除缺陷配置",
			delete: async function(defectConfigId) {
				return await http.delete(this.url +defectConfigId);
			}
		},
	},
	DetectionTool:{
		listDetectionTool: {
			url: `${config.API_URL}/mes/qc/detectionTool/list`,
			name: "查询检测工具列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getDetectionTool: {
			url: `${config.API_URL}/mes/qc/detectionTool/`,
			name: "查询检测工具详细",
			get: async function(detectionToolId) {
				return await http.get(this.url +detectionToolId);
			}
		},
		addDetectionTool: {
			url: `${config.API_URL}/mes/qc/detectionTool`,
			name: "新增检测工具",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateDetectionTool: {
			url: `${config.API_URL}/mes/qc/detectionTool`,
			name: "修改检测工具",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delDetectionTool: {
			url: `${config.API_URL}/mes/qc/detectionTool/`,
			name: "删除检测工具",
			delete: async function(detectionToolId) {
				return await http.delete(this.url +detectionToolId);
			}
		},
	},
	DetectionType: {
		listDetectionType: {
			url: `${config.API_URL}/mes/qc/detectionType/list`,
			name: "查询检测种类列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getDetectionType: {
			url: `${config.API_URL}/mes/qc/detectionType/`,
			name: "查询检测种类详细",
			get: async function(detectionTypeId) {
				return await http.get(this.url +detectionTypeId);
			}
		},
		addDetectionType: {
			url: `${config.API_URL}/mes/qc/detectionType`,
			name: "新增检测种类",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateDetectionType: {
			url: `${config.API_URL}/mes/qc/detectionType`,
			name: "修改检测种类",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delDetectionType: {
			url: `${config.API_URL}/mes/qc/detectionType/`,
			name: "删除检测种类",
			delete: async function(detectionTypeId) {
				return await http.delete(this.url +detectionTypeId);
			}
		},
	},
	TemplateDetection: {
		getTemplateDetection: {
			url: `${config.API_URL}/mes/qc/templateDetection/byTemplateId/`,
			name: "查询检测设置",
			get: async function(templateId) {
				return await http.get(this.url +templateId);
			}
		},
	},
	TemplateDefect: {
		getTemplateDefect: {
			url: `${config.API_URL}/mes/qc/templateDefect/byTemplateId/`,
			name: "查询缺陷设置",
			get: async function(templateId) {
				return await http.get(this.url +templateId);
			}
		},
	},
	TemplateAssistplan: {
		listTemplateAssistplan: {
			url: `${config.API_URL}/mes/qc/templateAssistplan/list`,
			name: "查询检测模板-辅助作业列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getTemplateAssistplan: {
			url: `${config.API_URL}/mes/qc/templateAssistplan/`,
			name: "查询检测模板-辅助作业详细",
			get: async function(templateAssistplanId) {
				return await http.get(this.url +templateAssistplanId);
			}
		},
	},
}
