import config from "@/config"
import http from "@/utils/request"

export default {
	target: {
		listTarget: {
			url: `${config.API_URL}/erp/report/target/list`,
			name: "查询erp毛利率模板列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getTarget: {
			url: `${config.API_URL}/erp/report/target/`,
			name: "查询erp毛利率模板详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addTarget: {
			url: `${config.API_URL}/erp/report/target`,
			name: "新增erp毛利率模板",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateTarget: {
			url: `${config.API_URL}/erp/report/target`,
			name: "修改erp毛利率模板",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delTarget: {
			url: `${config.API_URL}/erp/report/target/`,
			name: "删除erp毛利率模板",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/report/target/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`target`");
			}
		},
	}
}
