import api from '@/api'

const user = {
	state: {
		avatar: '',
		noticeListSum: '',
		msgListSum: '',
	},
	mutations: {
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_NOTICE: (state, noticeListSum) => {
			state.noticeListSum = noticeListSum
		},
		SET_MEG: (state, msgListSum) => {
			state.msgListSum = msgListSum
		},
	},
	actions: {
		// 获取用户信息
		GetInfo({commit}) {
			return new Promise((resolve, reject) => {
				api.auth.getInfo.get().then(res => {
					const user = res.user
					if (user != undefined) {
						if (!Reflect.has(user, 'avatar')) {
							const avatar = (user.avatar == "" || user.avatar == null) ? res.user.userName.substring(0, 1) : user.avatar;
							commit('SET_AVATAR', avatar)
						} else {
							commit('SET_AVATAR', user.avatar)
						}
						resolve(res)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取公告信息
		GetNotice({commit}) {
			return new Promise((resolve, reject) => {
				api.notice.listUnRead.get({
					recipientId: this.$TOOL.data.get("USER_INFO").userId,
					status: 'UNREAD'
				}).then(res => {
					if (res.rows != undefined) {
						let noticeList = [];
						res.rows.forEach(function (item) {
							noticeList.push({
								id: item.noticeId,
								type: item.noticeType,
								avatar: "img/logo.png",
								title: item.noticeTitle,
								describe: item.noticeContent,
								link: "",
								time: item.createTime
							})
						});
						commit('SET_NOTICE', noticeList.length)
						resolve(res)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取消息信息
		GetMsg({commit}) {
			return new Promise((resolve, reject) => {
				api.system.message.get({
					recipientId: this.$TOOL.data.get("USER_INFO").userId,
					status: 'UNREAD'
				}).then(res => {
					if (res.rows != undefined) {
						let msgList = [];
						res.rows.forEach(function (item) {
							msgList.push({
								id: item.messageId,
								type: item.messageType,
								avatar: "img/logo.png",
								title: item.messageTitle,
								describe: item.messageContent,
								link: "",
								time: item.createTime
							})
						});
						commit('SET_MEG', msgList.length)
						resolve(res)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
	}
}

export default user
