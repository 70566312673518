import config from "@/config"
import http from "@/utils/request"

export default {
	// 扫码
	getMdItem: {
		url: `${config.API_URL}/erp/problem/problemFeedback/getInfoItemId/`,
		name: "查询物料详细",
		get: async function (itemId) {
			return await http.get(this.url + itemId);
		}
	},
	addProblemFeedback: {
		url: `${config.API_URL}/erp/problem/problemFeedback/addProblemFeedback`,
		name: "问题反馈提交",
		post: async function (data) {
			return await http.post(this.url,data);
		}
	},
	getProblemType: {
		url: `${config.API_URL}/erp/problem/problemFeedback/scanList`,
		name: "问题类型",
		get: async function (data) {
			return await http.get(this.url,data);
		}
	},
}
