import config from "@/config"
import http from "@/utils/request"


export default {
	listConfig: {
		url: `${config.API_URL}/system/config/list`,
		name: "查询参数列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
  listConfig1: {
		url: `${config.API_URL}/system/config/list1`,
		name: "查询参数列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	getConfig: {
		url: `${config.API_URL}/system/config/`,
		name: "查询参数详细",
		get: async function(configId){
			return await http.get(this.url +configId);
		}
	},
  getConfigKey: {
		url: `${config.API_URL}/system/config/configKey/`,
		name: "根据参数键名查询参数值",
		get: async function(configKey){
			return await http.get(this.url +configKey);
		}
	},
  addConfig: {
		url: `${config.API_URL}/system/config`,
		name: "新增参数配置",
		post: async function(data){
			return await http.post(this.url,data);
		}
	},
  updateConfig: {
		url: `${config.API_URL}/system/config`,
		name: "修改参数配置",
		put: async function(data){
			return await http.put(this.url,data);
		}
	},
  delConfig: {
		url: `${config.API_URL}/system/config/`,
		name: "删除参数配置",
		delete: async function(configId){
			return await http.delete(this.url+configId);
		}
	},
  refreshCache: {
		url: `${config.API_URL}/system/config/refreshCache`,
		name: "刷新参数缓存",
		delete: async function(){
			return await http.delete(this.url);
		}
	},
  exportFile1: {
		url: `${config.API_URL}system/config/export1`,
		name: "导出附件",
		get: async function(data, config={}){
			return await http.get(this.url, data, config);
		}
	},
  exportFile: {
		url: `${config.API_URL}system/config/export`,
		name: "导出附件",
		post: async function(data, config={}){
			return await http.postDom(this.url, data, config,"config");
		}
	},
}
