import config from "@/config"
import http from "@/utils/request"

export default {
	/**
	 * 供应商性质
	 */
	vendorBusinessNature:{
		listVendorBusinessNature: {
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature/list`,
			name: "查询供应商营业性质列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getVendorBusinessNature: {
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature/`,
			name: "查询供应商营业性质详细",
			get: async function(vendorBusinessNatureId) {
				return await http.get(this.url +vendorBusinessNatureId);
			}
		},
		addVendorBusinessNature: {
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature`,
			name: "新增供应商营业性质",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateVendorBusinessNature: {
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature`,
			name: "修改供应商营业性质",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delVendorBusinessNature: {
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature/`,
			name: "删除供应商营业性质",
			delete: async function(vendorBusinessNatureId) {
				return await http.delete(this.url +vendorBusinessNatureId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "vendorBusinessNature");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/vendor/vendorBusinessNature/changeStatus`,
			name: "供应商营业性质状态修改",
			put: async function(vendorBusinessNatureId, status){
				const data = {
					vendorBusinessNatureId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 供应商类别
	 */
	vendorCategory:{
		listCategory: {
			url: `${config.API_URL}/erp/vendor/category/list`,
			name: "查询供应商类别列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getCategory: {
			url: `${config.API_URL}/erp/vendor/category/`,
			name: "查询供应商类别详细",
			get: async function(vendorCategoryId) {
				return await http.get(this.url +vendorCategoryId);
			}
		},
		addCategory: {
			url: `${config.API_URL}/erp/vendor/category`,
			name: "新增供应商类别",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateCategory: {
			url: `${config.API_URL}/erp/vendor/category`,
			name: "修改供应商类别",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delCategory: {
			url: `${config.API_URL}/erp/vendor/category/`,
			name: "删除供应商类别",
			delete: async function(vendorCategoryId) {
				return await http.delete(this.url +vendorCategoryId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/vendor/category/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "vendorCategory");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/vendor/category/changeStatus`,
			name: "供应商类别状态修改",
			put: async function(vendorCategoryId, status){
				const data = {
					vendorCategoryId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 供应商等级
	 */
	vendorGrade:{
		listGrade: {
			url: `${config.API_URL}/erp/vendor/grade/list`,
			name: "查询供应商等级列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getGrade: {
			url: `${config.API_URL}/erp/vendor/grade/`,
			name: "查询供应商等级详细",
			get: async function(gradeId) {
				return await http.get(this.url +gradeId);
			}
		},
		addGrade: {
			url: `${config.API_URL}/erp/vendor/grade`,
			name: "新增供应商等级",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateGrade: {
			url: `${config.API_URL}/erp/vendor/grade`,
			name: "修改供应商等级",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delGrade: {
			url: `${config.API_URL}/erp/vendor/grade/`,
			name: "删除供应商等级",
			delete: async function(gradeId) {
				return await http.delete(this.url +gradeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/vendor/grade/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "grade");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/vendor/grade/changeStatus`,
			name: "供应商等级状态修改",
			put: async function(vendorGradeId, status){
				const data = {
					vendorGradeId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 供应商行业
	 */
	vendorProfession:{
		listProfession: {
			url: `${config.API_URL}/erp/vendor/profession/list`,
			name: "查询供应商行业列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getProfession: {
			url: `${config.API_URL}/erp/vendor/profession/`,
			name: "查询供应商行业详细",
			get: async function(vendorProfessionId) {
				return await http.get(this.url +vendorProfessionId);
			}
		},
		addProfession: {
			url: `${config.API_URL}/erp/vendor/profession`,
			name: "新增供应商行业",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateProfession: {
			url: `${config.API_URL}/erp/vendor/profession`,
			name: "修改供应商行业",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delProfession: {
			url: `${config.API_URL}/erp/vendor/profession/`,
			name: "删除供应商行业",
			delete: async function(vendorProfessionId) {
				return await http.delete(this.url +vendorProfessionId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/vendor/profession/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "profession");
			}
		},
		changeRoleStatus:{
			url: `${config.API_URL}/erp/vendor/profession/changeStatus`,
			name: "供应商行业状态修改",
			put: async function(vendorProfessionId, status){
				const data = {
					vendorProfessionId,
					status
				}
				return await http.put(this.url,data);
			}
		},
	},
	/**
	 * 供应商管理
	 */
	vendor:{
		listVendor: {
			url: `${config.API_URL}/erp/vendor/list`,
			name: "查询供应商列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		listInfos: {
			url: `${config.API_URL}/erp/vendor/listInfos`,
			name: "查询供应商列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getVendor: {
			url: `${config.API_URL}/erp/vendor/`,
			name: "查询供应商详细",
			get: async function(vendorId) {
				return await http.get(this.url +vendorId);
			}
		},
		addVendor: {
			url: `${config.API_URL}/erp/vendor`,
			name: "新增供应商",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateVendor: {
			url: `${config.API_URL}/erp/vendor`,
			name: "修改供应商",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delVendor: {
			url: `${config.API_URL}/erp/vendor/`,
			name: "删除供应商",
			delete: async function(vendorId) {
				return await http.delete(this.url +vendorId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/vendor/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`vendor`");
			}
		},
		listInfo: {
			url: `${config.API_URL}/erp/vendor/listInfo`,
			name: "查询供应商关联信息",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		vendorItemRemove: {
			url: `${config.API_URL}/erp/vendor/vendorItemRemove`,
			name: "删除物料关联供应商",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		vendorItemAdd: {
			url: `${config.API_URL}/erp/vendor/vendorItemAdd`,
			name: "添加物料关联供应商",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		exportVendoerItem: {
			url: `${config.API_URL}/erp/vendor/exportVendoerItem`,
			name: "导出供应商关联的物料",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "vendoerItem");
			}
		},
		selectVendoerItem: {
			url: `${config.API_URL}/erp/vendor/selectVendoerItem`,
			name: "查询供应商关联的物料",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
	}
}
