<!--
 * @Descripttion: 生成条码组件
 * @version: 1.0
 * @Author: jensen
 * @Date: 2023年09月20日14:22:20
 * @LastEditors:
 * @LastEditTime:
-->

<template>
	<svg id="barcode"></svg>
</template>

<script>
import JsBarcode from 'jsbarcode';
// import {ElMessageBox} from "element-plus";

export default {
	props: {
		text: { type: String, required: true, default: "" },
		textPosition: {type: String, default: "bottom"},
		displayValue: {type: Boolean, default: true},
		width: {type: Number, default: 1},
		height: {type: Number, default: 36},
		codeFormat: {type: Number, default: 1},
		lineColor: {type: String, default: "#000000"},
		background: {type: String, default: "#ffffff"},
		fontSize: {type: Number, default: 14},
		margin: {type: Number, default: 8}
	},
	data() {
		return {
			formats: ['CODE39', 'CODE128', 'EAN5', 'EAN13', 'upc', 'pharmacode']
		}
	},
	watch: {
		text() {
			this.draw()
		}
	},
	mounted() {
		this.draw()
	},
	methods: {
		draw() {
			if(this.text == ""){
				return
			}
			this.$nextTick(() => {
				try {
					JsBarcode('#barcode', this.text, {
						format: this.formats[this.codeFormat], //选择要使用的条形码类型
						width: this.width, //线宽
						height: this.height, //条码高度
						text: this.text, //显示文本
						displayValue: this.displayValue, //是否在条形码下方显示文字
						textPosition: this.textPosition, //设置文本的垂直位置
						// fontOptions: "bold italic", //使文字加粗体或变斜体
						background: this.background, //设置条形码的背景
						// font: "fantasy", //设置文本的字体
						lineColor: this.lineColor, //设置条和文本的颜色。
						fontSize: this.fontSize, //设置文本的大小
						margin: this.margin //设置条形码周围的空白边距
					});
				} catch (e) {
					// ElMessageBox.alert("错误的输入，请检查输入内容！");
				}
			});
		}
	}
}
</script>

<style>
</style>
