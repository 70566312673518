import config from "@/config"
import http from "@/utils/request"
export default {
  listType:{
    url: `${config.API_URL}/system/dict/type/list`,
    name: "查询字典类型列表",
    get: async function(query){
      return await http.get(this.url,query);
    }
  },
  getType:{
    url: `${config.API_URL}/system/dict/type/`,
    name: "查询字典类型详细",
    get: async function(dictId){
      return await http.get(this.url + dictId);
    }
  },
  addType:{
    url: `${config.API_URL}/system/dict/type`,
    name: "新增字典类型",
    post: async function(data){
      return await http.post(this.url , data);
    }
  },
  updateType:{
    url: `${config.API_URL}/system/dict/type`,
    name: "修改字典类型",
    put: async function(data){
      return await http.put(this.url , data);
    }
  },
  delType:{
    url: `${config.API_URL}/system/dict/type/`,
    name: "删除字典类型",
    delete: async function(dictId){
      return await http.delete(this.url+dictId );
    }
  },
  refreshCache:{
    url: `${config.API_URL}/system/dict/type/refreshCache`,
    name: "刷新字典缓存",
    delete: async function(){
      return await http.delete(this.url );
    }
  },
  optionselect:{
    url: `${config.API_URL}/system/dict/type/optionselect`,
    name: "获取字典选择框列表",
    get: async function(){
      return await http.get(this.url );
    }
  },
  exportFile: {
		url: `${config.API_URL}system/dict/type/export`,
		name: "导出附件",
		post: async function(data, config={}){
			return await http.postDom(this.url, data, config,"type");
		}
	},
  exportFile1: {
		url: `${config.API_URL}system/dict/type/export1`,
		name: "导出附件",
		get: async function(data, config={}){
			return await http.get(this.url, data, config);
		}
	},
}