import config from "@/config"
import http from "@/utils/request"

export default {
	pruchase: {
		pruchaseList:{
			url: `${config.API_URL}/erp/supplyChain/purchase/list`,
			name: "查询销售订单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		confirmPurchase:{
			url: `${config.API_URL}/erp/supplyChain/purchase/confirm`,
			name: "供应商确认收到采购需求",
			put: async function (query) {
				return await http.put(this.url, query);
			}
		},
		sendGoods:{
			url: `${config.API_URL}/erp/supplyChain/purchase/sendGoods`,
			name: "供应商发货",
			put: async function (query) {
				return await http.put(this.url, query);
			}
		},
		getItemDetail:{
			url: `${config.API_URL}/erp/supplyChain/purchase/getItem/`,
			name: "查询采购需求详情",
			get: async function (id) {
				return await http.get(this.url+id);
			}
		},
	}
}
