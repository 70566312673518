import config from "@/config"
import http from "@/utils/request"


export default {
	list: {
		url: `${config.API_URL}/monitor/logininfor/list`,
		name: "查询登录日志列表",
		get: async function(query){
			return await http.get(this.url, query);
		}
	},
  delLogininfor: {
		url: `${config.API_URL}/monitor/logininfor/`,
		name: "删除登录日志",
		delete: async function(infoId){
			return await http.delete(this.url+infoId);
		}
	},
  cleanLogininfor: {
		url: `${config.API_URL}/monitor/logininfor/clean`,
		name: "清空登录日志",
		delete: async function(query){
			return await http.delete(this.url,query);
		}
	},
  exportFile1: {
		url: `${config.API_URL}monitor/logininfor/export1`,
		name: "导出附件",
		get: async function(data, config={}){
			return await http.get(this.url, data, config);
		}
	},
}
