import config from "@/config"
import http from "@/utils/request"

export default {
	cache:{
		getCache: {
			url: `${config.API_URL}/monitor/cache`,
			name: "查询缓存详细",
			get: async function () {
				return await http.get(this.url, );
			}
		},
	},
	job:{
		listJob: {
			url: `${config.API_URL}/monitor/job/list`,
			name: "查询定时任务调度列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getJob: {
			url: `${config.API_URL}/monitor/job/`,
			name: "查询定时任务调度详细",
			get: async function (jobId) {
				return await http.get(this.url + jobId);
			}
		},
		addJob: {
			url: `${config.API_URL}/monitor/job`,
			name: "新增定时任务调度",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateJob: {
			url: `${config.API_URL}/monitor/job`,
			name: "修改定时任务调度",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delJob: {
			url: `${config.API_URL}/monitor/job/`,
			name: "删除定时任务调度",
			delete: async function (jobId) {
				return await http.delete(this.url + jobId, jobId);
			}
		},
		changeJobStatus: {
			url: `${config.API_URL}/monitor/job/changeStatus`,
			name: "任务状态修改",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		runJob: {
			url: `${config.API_URL}/monitor/job/run`,
			name: "定时任务立即执行一次",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
	},
	jobLog:{
		listJobLog: {
			url: `${config.API_URL}/monitor/jobLog/list`,
			name: "查询调度日志列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		delJobLog: {
			url: `${config.API_URL}/monitor/jobLog/`,
			name: "删除调度日志",
			delete: async function (jobLogId) {
				return await http.delete(this.url + jobLogId, jobLogId);
			}
		},
		cleanJobLog: {
			url: `${config.API_URL}/monitor/jobLog/clean`,
			name: "清空调度日志",
			delete: async function () {
				return await http.delete(this.url);
			}
		},
		exportFile: {
			url: `${config.API_URL}/monitor/jobLog/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "jobLog");
			}
		}
	},
	logininfor:{
		list: {
			url: `${config.API_URL}/monitor/logininfor/list`,
			name: "查询登录日志列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		delLogininfor: {
			url: `${config.API_URL}/monitor/logininfor/`,
			name: "删除登录日志",
			delete: async function (infoId) {
				return await http.delete(this.url + infoId, infoId);
			}
		},
		cleanJobLog: {
			url: `${config.API_URL}/monitor/logininfor/clean`,
			name: "清空登录日志",
			delete: async function () {
				return await http.delete(this.url);
			}
		},
	},
	online:{
		list: {
			url: `${config.API_URL}/monitor/online/list`,
			name: "查询在线用户列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		delLogininfor: {
			url: `${config.API_URL}/monitor/online/`,
			name: "强退用户",
			delete: async function (tokenId) {
				return await http.delete(this.url + tokenId, tokenId);
			}
		},
	},
	operlog:{
		list: {
			url: `${config.API_URL}/monitor/operlog/list`,
			name: "查询操作日志列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		delOperlog: {
			url: `${config.API_URL}/monitor/operlog/`,
			name: "删除操作日志",
			delete: async function (operId) {
				return await http.delete(this.url + operId, operId);
			}
		},
		cleanOperlog: {
			url: `${config.API_URL}/monitor/operlog/clean`,
			name: "清空操作日志",
			delete: async function () {
				return await http.delete(this.url);
			}
		},
	},
	server:{
		getCache: {
			url: `${config.API_URL}/monitor/server`,
			name: "获取服务信息",
			get: async function () {
				return await http.get(this.url);
			}
		},
	}
}
