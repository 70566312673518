import config from "@/config"
import http from "@/utils/request"
export default {
  dept:{
    listDept:{
      url: `${config.API_URL}/system/dept/list`,
			name: "查询部门列表",
			get: async function(query){
				return await http.get(this.url,query);
			}
    },
    listDeptTree:{
      url: `${config.API_URL}/system/dept/listDept`,
			name: "查询部门列表",
			get: async function(query){
				return await http.get(this.url,query);
			}
    },
    listDeptExcludeChild:{
      url: `${config.API_URL}/system/dept/list/exclude/`,
			name: "查询部门列表（排除节点）",
			get: async function(query){
				return await http.get(this.url + query);
			}
    },
    getDept:{
      url: `${config.API_URL}/system/dept/`,
			name: "查询部门详细",
			get: async function(deptId){
				return await http.get(this.url + deptId);
			}
    },
    treeselect:{
      url: `${config.API_URL}/system/dept/treeselect`,
			name: "查询部门下拉树结构",
			get: async function(query){
				return await http.get(this.url,query);
			}
    },
    roleDeptTreeselect:{
      url: `${config.API_URL}/system/dept/roleDeptTreeselect/`,
			name: "根据角色ID查询部门树结构",
			get: async function(roleId){
				return await http.get(this.url + roleId);
			}
    },
    addDept:{
      url: `${config.API_URL}/system/dept`,
			name: "新增部门",
			post: async function(data){
				return await http.post(this.url,data);
			}
    },
    updateDept:{
      url: `${config.API_URL}/system/dept`,
			name: "修改部门",
			put: async function(data){
				return await http.put(this.url,data);
			}
    },
    delDept:{
      url: `${config.API_URL}/system/dept/`,
			name: "修改部门",
			delete: async function(deptId){
				return await http.delete(this.url + deptId,deptId);
			}
    },
  }
}
