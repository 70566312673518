import config from "@/config"
import http from "@/utils/request"

export default {
	checkmachinery: {
		listCheckmachinery: {
			url: `${config.API_URL}/mes/dv/checkmachinery/list`,
			name: "查询点检设备列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getCheckmachinery: {
			url: `${config.API_URL}/mes/dv/checkmachinery/`,
			name: "查询点检设备详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addCheckmachinery: {
			url: `${config.API_URL}/mes/dv/checkmachinery`,
			name: "新增点检设备",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateCheckmachinery: {
			url: `${config.API_URL}/mes/dv/checkmachinery`,
			name: "修改点检设备",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delCheckmachinery: {
			url: `${config.API_URL}/mes/dv/checkmachinery/`,
			name: "删除点检设备",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/checkmachinery/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "checkmachinery");
			}
		}

	},
	checkplan: {
		listCheckplan: {
			url: `${config.API_URL}/mes/dv/checkplan/list`,
			name: "查询设备点检计划头列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getCheckplan: {
			url: `${config.API_URL}/mes/dv/checkplan/`,
			name: "查询设备点检计划头详细",
			get: async function (planId) {
				return await http.get(this.url + planId);
			}
		},
		addCheckplan: {
			url: `${config.API_URL}/mes/dv/checkplan`,
			name: "新增设备点检计划头",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateCheckplan: {
			url: `${config.API_URL}/mes/dv/checkplan`,
			name: "修改设备点检计划头",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delCheckplan: {
			url: `${config.API_URL}/mes/dv/checkplan/`,
			name: "删除设备点检计划头",
			delete: async function (planId) {
				return await http.delete(this.url + planId, planId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/checkplan/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "checkplan");
			}
		}
	},
	checksubject: {
		listChecksubject: {
			url: `${config.API_URL}/mes/dv/checksubject/list`,
			name: "查询点检项目列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getChecksubject: {
			url: `${config.API_URL}/mes/dv/checksubject/`,
			name: "查询点检项目详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addChecksubject: {
			url: `${config.API_URL}/mes/dv/checksubject`,
			name: "新增点检项目",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateChecksubject: {
			url: `${config.API_URL}/mes/dv/checksubject`,
			name: "修改点检项目",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delChecksubject: {
			url: `${config.API_URL}/mes/dv/checksubject/`,
			name: "删除点检项目",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/checksubject/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "checksubject");
			}
		}

	},
	dvsubject: {
		listDvsubject: {
			url: `${config.API_URL}/mes/dv/dvsubject/list`,
			name: "查询设备点检保养项目列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getDvsubject: {
			url: `${config.API_URL}/system/dept/`,
			name: "查询设备点检保养项目详细",
			get: async function (subjectId) {
				return await http.get(this.url + subjectId);
			}
		},
		addDvsubject: {
			url: `${config.API_URL}/mes/dv/dvsubject`,
			name: "新增设备点检保养项目",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateDvsubject: {
			url: `${config.API_URL}/mes/dv/dvsubject`,
			name: "修改设备点检保养项目",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delDvsubject: {
			url: `${config.API_URL}/mes/dv/dvsubject/`,
			name: "删除设备点检保养项目",
			delete: async function (subjectId) {
				return await http.delete(this.url + subjectId, subjectId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/dvsubject/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "dvsubject");
			}
		}

	},
	machinery: {
		listMachinery: {
			url: `${config.API_URL}/mes/dv/machinery/list`,
			name: "查询设备列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getMachinery: {
			url: `${config.API_URL}/mes/dv/machinery/`,
			name: "查询设备详细",
			get: async function (machineryId) {
				return await http.get(this.url + machineryId);
			}
		},
		addMachinery: {
			url: `${config.API_URL}/mes/dv/machinery`,
			name: "新增设备",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateMachinery: {
			url: `${config.API_URL}/mes/dv/machinery`,
			name: "修改设备",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delMachinery: {
			url: `${config.API_URL}/mes/dv/machinery/`,
			name: "删除设备",
			delete: async function (machineryId) {
				return await http.delete(this.url + machineryId, machineryId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/machinery/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "machinery");
			}
		},
		importTemplate: {
			url: `${config.API_URL}/mes/dv/machinery/importTemplate`,
			name: "下载模板",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "machinery");
			}
		},
		importTemplate1: {
			url: `${config.API_URL}/mes/dv/machinery/importTemplate`,
			name: "下载模板",
			get: async function(data, config={}){
				return await http.get(this.url, data, config);
			}
		},
	},
	scheduling:{
		listScheduling: {
			url: `${config.API_URL}/md/scheduling/list`,
			name: "查询设备排程信息列表",
			get: async function(data) {
			  return await http.get(this.url, data);
			}
		  },
		  getScheduling: {
			url: `${config.API_URL}/md/scheduling/`,
			name: "查询设备排程信息详细",
			get: async function(schedulingId) {
			  return await http.get(this.url +schedulingId);
			}
		  },
		  addScheduling: {
			url: `${config.API_URL}/md/scheduling`,
			name: "新增设备排程信息",
			post: async function(data) {
			  return await http.post(this.url ,data);
			}
		  },
		  updateScheduling: {
			url: `${config.API_URL}/md/scheduling`,
			name: "修改设备排程信息",
			put: async function(data) {
			  return await http.put(this.url ,data);
			}
		  },
		  delScheduling: {
			url: `${config.API_URL}/md/scheduling/`,
			name: "删除设备排程信息",
			delete: async function(schedulingId) {
			  return await http.delete(this.url +schedulingId);
			}
		  },
	},
	machinerytype: {
		listMachinerytype: {
			url: `${config.API_URL}/mes/dv/machinerytype/list`,
			name: "查询设备类型列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getMachinerytype: {
			url: `${config.API_URL}/mes/dv/machinerytype/`,
			name: "查询设备类型详细",
			get: async function (machineryTypeId) {
				return await http.get(this.url + machineryTypeId);
			}
		},
		addMachinerytype: {
			url: `${config.API_URL}/mes/dv/machinerytype`,
			name: "新增设备类型",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateMachinerytype: {
			url: `${config.API_URL}/mes/dv/machinerytype`,
			name: "修改设备类型",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delMachinerytype: {
			url: `${config.API_URL}/mes/dv/machinerytype/`,
			name: "删除设备类型",
			delete: async function (machineryTypeId) {
				return await http.delete(this.url + machineryTypeId, machineryTypeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/machinerytype/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "machinerytype");
			}
		},
		listAll: {
			url: `${config.API_URL}/mes/dv/machinerytype/listAll`,
			name: "查询所有设备类型",
			get: async function () {
				return await http.get(this.url);
			}
		},

	},
	repair: {
		listRepair: {
			url: `${config.API_URL}/mes/dv/repair/list`,
			name: "查询设备维修单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getRepair: {
			url: `${config.API_URL}/mes/dv/repair/`,
			name: "查询设备维修单详细",
			get: async function (repairId) {
				return await http.get(this.url + repairId);
			}
		},
		addRepair: {
			url: `${config.API_URL}/mes/dv/repair`,
			name: "新增设备维修单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRepair: {
			url: `${config.API_URL}/mes/dv/repair`,
			name: "修改设备维修单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRepair: {
			url: `${config.API_URL}/mes/dv/repair/`,
			name: "删除设备维修单",
			delete: async function (repairId) {
				return await http.delete(this.url + repairId, repairId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/repair/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "repair");
			}
		}
	},
	repairline: {
		listRepairline: {
			url: `${config.API_URL}/mes/dv/repairline/list`,
			name: "查询设备维修单行列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getRepairline: {
			url: `${config.API_URL}/mes/dv/repairline/`,
			name: "查询设备维修单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addRepairline: {
			url: `${config.API_URL}/mes/dv/repairline`,
			name: "新增设备维修单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRepairline: {
			url: `${config.API_URL}/mes/dv/repairline`,
			name: "修改设备维修单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRepairline: {
			url: `${config.API_URL}/mes/dv/repairline/`,
			name: "删除设备维修单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId, lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/repairline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "repairline");
			}
		}
	},
	checkRecord: {
		listCheckRecord: {
			url: `${config.API_URL}/mes/dv/checkRecord/list`,
			name: "查询点检保养记录列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getCheckRecord: {
			url: `${config.API_URL}/mes/dv/checkRecord/`,
			name: "查询点检保养记录详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addCheckRecord: {
			url: `${config.API_URL}/mes/dv/checkRecord`,
			name: "新增点检保养记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateCheckRecord: {
			url: `${config.API_URL}/mes/dv/checkRecord`,
			name: "修改点检保养记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delCheckRecord: {
			url: `${config.API_URL}/mes/dv/checkRecord/`,
			name: "删除点检保养记录",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/dv/checkRecord/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "点检保养记录");
			}
		},
		executeSave: {
			url: `${config.API_URL}/mes/dv/checkRecord/executeSave`,
			name: "点检保养记录 执行-保存",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/dv/checkRecord/execute`,
			name: "点检保养记录 执行-提交",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		approve: {
			url: `${config.API_URL}/mes/dv/checkRecord/approve`,
			name: "点检保养记录审批",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		withDraw: {
			url: `${config.API_URL}/mes/dv/checkRecord/withDraw`,
			name: "点检保养记录撤回",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		}
	},
}
