import config from "@/config"
import http from "@/utils/request"

export default {
	listRead: {
		url: `${config.API_URL}/system/noticeRead/list`,
		name: "查询公告阅读关联列表",
		get: async function(data) {
			return await http.get(this.url, data);
		}
	},
	getRead: {
		url: `${config.API_URL}/system/noticeRead/`,
		name: "查询公告阅读关联详细",
		get: async function(noticeReadId) {
			return await http.get(this.url +noticeReadId);
		}
	},
	addRead: {
		url: `${config.API_URL}/system/noticeRead`,
		name: "新增公告阅读关联",
		post: async function(data) {
			return await http.post(this.url ,data);
		}
	},
	updateRead: {
		url: `${config.API_URL}/system/noticeRead`,
		name: "修改公告阅读关联",
		put: async function(data) {
			return await http.put(this.url ,data);
		}
	},
	delRead: {
		url: `${config.API_URL}/system/noticeRead/`,
		name: "删除公告阅读关联",
		delete: async function(noticeReadId) {
			return await http.delete(this.url +noticeReadId);
		}
	},
}
