import config from "@/config"
import http from "@/utils/request"

export default {
	dry: {
		listDry: {
			url: `${config.API_URL}/screen/produce/record/listDry`,
			name: "查询干压生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportDry: {
			url: `${config.API_URL}/screen/produce/record/exportDry`,
			name: "导出干压生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "干压生产记录表");
			}
		}
	},
	hydraulic: {
		listHydraulic: {
			url: `${config.API_URL}/screen/produce/record/listHydraulic`,
			name: "查询液压生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportHydraulic: {
			url: `${config.API_URL}/screen/produce/record/exportHydraulic`,
			name: "导出液压生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "液压生产记录表");
			}
		}
	},
	isostatic: {
		listIsostatic: {
			url: `${config.API_URL}/screen/produce/record/listIsostatic`,
			name: "查询等静压生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportIsostatic: {
			url: `${config.API_URL}/screen/produce/record/exportIsostatic`,
			name: "导出等静压生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "等静压生产记录表");
			}
		}
	},
	milling: {
		listMilling: {
			url: `${config.API_URL}/screen/produce/record/listMilling`,
			name: "查询铣加工生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportMilling: {
			url: `${config.API_URL}/screen/produce/record/exportMilling`,
			name: "导出铣加工生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "铣加工生产记录表");
			}
		}
	},
	carProcess: {
		listCarProcess: {
			url: `${config.API_URL}/screen/produce/record/listCarProcess`,
			name: "查询车加工生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportCarProcess: {
			url: `${config.API_URL}/screen/produce/record/exportCarProcess`,
			name: "导出车加工生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "车加工生产记录表");
			}
		}
	},
	stickProcess: {
		listStickProcess: {
			url: `${config.API_URL}/screen/produce/record/listStickProcess`,
			name: "查询粘框生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportStickProcess: {
			url: `${config.API_URL}/screen/produce/record/exportStickProcess`,
			name: "导出粘框生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "粘框生产记录表");
			}
		}
	},
	packProcess: {
		listPackProcess: {
			url: `${config.API_URL}/screen/produce/record/listPackProcess`,
			name: "查询贴标生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportPackProcess: {
			url: `${config.API_URL}/screen/produce/record/exportPackProcess`,
			name: "导出贴标生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "贴标生产记录表");
			}
		}
	},
	burnProcess: {
		listBurnProcess: {
			url: `${config.API_URL}/screen/produce/record/listBurnProcess`,
			name: "查询素烧生产记录表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportBurnProcess: {
			url: `${config.API_URL}/screen/produce/record/exportBurnProcess`,
			name: "导出素烧生产记录表",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "素烧生产记录表");
			}
		}
	},
}
