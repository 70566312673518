import config from "@/config"
import http from "@/utils/request"

export default {
	reason: {
		listReason: {
			url: `${config.API_URL}/mes/andon/reason/list`,
			name: "查询安灯原因列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		addReason: {
			url: `${config.API_URL}/mes/andon/reason`,
			name: "新增安灯原因",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateReason: {
			url: `${config.API_URL}/mes/andon/reason`,
			name: "修改安灯原因",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delReason: {
			url: `${config.API_URL}/mes/andon/reason/`,
			name: "删除安灯原因",
			delete: async function (id) {
				return await http.delete(this.url + id, id);
			}
		},
	},
	record: {
		listRecord: {
			url: `${config.API_URL}/mes/andon/record/list`,
			name: "查询安灯记录列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		addRecord: {
			url: `${config.API_URL}/mes/andon/record`,
			name: "新增安灯记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRecord: {
			url: `${config.API_URL}/mes/andon/record`,
			name: "修改安灯记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRecord: {
			url: `${config.API_URL}/mes/andon/record/`,
			name: "删除安灯记录",
			delete: async function (id) {
				return await http.delete(this.url + id, id);
			}
		},
		answerOrClose: {
			url: `${config.API_URL}/mes/andon/record/answerOrClose`,
			name: "响应或关闭安灯记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		getDealUser: {
			url: `${config.API_URL}/mes/andon/record/getDealUser`,
			name: "获取处理人",
			get: async function () {
				return await http.get(this.url);
			}
		},
	},
}
