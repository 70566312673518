import config from "@/config"
import http from "@/utils/request"

export default {
  listNotice: {
    url: `${config.API_URL}/system/notice/list`,
    name: "查询通知公告列表",
    get: async function(data) {
      return await http.get(this.url, data);
    }
  },
  listNotice1: {
    url: `${config.API_URL}/system/notice/list1`,
    name: "查询通知公告列表",
    get: async function(data) {
      return await http.get(this.url, data);
    }
  },
  listUnRead: {
    url: `${config.API_URL}/system/notice/listUnRead`,
    name: "查询公告未读列表",
    get: async function(data) {
      return await http.get(this.url, data);
    }
  },
  getNotice: {
    url: `${config.API_URL}/system/notice/`,
    name: "查询通知公告详细",
    get: async function(noticeId) {
      return await http.get(this.url +noticeId);
    }
  },
  addNotice: {
    url: `${config.API_URL}/system/notice`,
    name: "新增通知公告",
    post: async function(data) {
      return await http.post(this.url ,data);
    }
  },
  updateNotice: {
    url: `${config.API_URL}/system/notice`,
    name: "修改通知公告",
    put: async function(data) {
      return await http.put(this.url ,data);
    }
  },
  delNotice: {
    url: `${config.API_URL}/system/notice/`,
    name: "删除通知公告",
    delete: async function(noticeId) {
      return await http.delete(this.url +noticeId);
    }
  },
	delkNewNotice: {
		url: `${config.API_URL}/system/notice/delkNewNotice`,
		name: "新删除通知公告",
		post: async function(data) {
			return await http.post(this.url ,data);
		}
	},
   listNoticeRead: {
		url: `${config.API_URL}/system/notice/listNotice`,
		name: "根据当前登录人获取信息公告",
		get: async function(data) {
			return await http.get(this.url,data );
		}
   },
}
