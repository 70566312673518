import config from "@/config"
import http from "@/utils/request"

export default {
	productTrace: {
		tracingBack: {
			url: `${config.API_URL}/mes/trace/product/tracingBack/`,
			name: "产品追溯",
			get: async function (batchCode) {
				return await http.get(this.url + batchCode);
			}
		},
		queryKeyWold: {
			url: `${config.API_URL}/mes/trace/product/queryKeyWold`,
			name: "查询关键字",
			get: async function () {
				return await http.get(this.url);
			}
		},
	},
}
