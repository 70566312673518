// 静态路由配置
// 书写格式与动态路由格式一致，全部经由框架统一转换
// 比较动态路由在meta中多加入了role角色权限，为数组类型。一个菜单是否有权限显示，取决于它以及后代菜单是否有权限。
// routes 显示在左侧菜单中的路由(显示顺序在动态路由之前)
// parent 归属的一级目录

const routes = [
	{
		name: "genEditTable",
		path: "/tool/gen-edit/index",
		component: "tool/gen/editTable",
		hidden: true,
		parent: "/tool",
		meta: {
			icon: "el-icon-edit",
			title: "修改生成配置",
			// role: ["admin"],
			hidden: true,
		}
	},
	{
		name: "itemDetailsTable",
		path: "/md/mditem/details",
		component: "mes/md/mditem/detail",
		hidden: true,
		parent: "/mes/md",
		meta: {
			icon: "el-icon-edit",
			title: "物料管理详情",
			hidden: true,
		}
	},
	{
		name: "productDetailsTable",
		path: "/md/mdproduct/details",
		component: "mes/md/mdproduct/detail",
		hidden: true,
		parent: "/mes/md",
		meta: {
			icon: "el-icon-edit",
			title: "产品管理详情",
			hidden: true,
		}
	},
	{
		name: "bomDetailsTable",
		path: "/md/bom/details",
		component: "mes/md/bom/detail",
		hidden: true,
		parent: "/mes/md",
		meta: {
			icon: "el-icon-edit",
			title: "BOM管理详情",
			hidden: true,
		}
	},
	{
		name: "sopDetailsTable",
		path: "/md/sop/details",
		component: "mes/md/sop/detail",
		hidden: true,
		parent: "/mes/md",
		meta: {
			icon: "el-icon-edit",
			title: "SOP管理详情",
			hidden: true,
		}
	},
	{
		name: "issueStockEdit",
		path: "/wm/issue/edit",
		component: "mes/wm/issue/save",
		hidden: true,
		parent: "/mes/wm",
		meta: {
			icon: "el-icon-edit",
			title: "修改生产领料单",
			hidden: true,
		}
	},
	{
		name: "erpissueStockEdit",
		path: "/wm/issue/edit",
		component: "mes/wm/issue/save",
		hidden: true,
		parent: "/returnMaterials",
		meta: {
			icon: "el-icon-edit",
			title: "修改生产领料单",
			hidden: true,
		}
	},
	{
		name: "qcTemplateEditTable",
		path: "/qc/qctemplate/save",
		component: "mes/qc/qctemplate/save",
		hidden: true,
		parent: "/mes/qc",
		meta: {
			icon: "el-icon-edit",
			title: "检测方案详情",
			hidden: true,
		}
	},
	{
		name: "ipqcEditTable",
		path: "/qc/ipqc/save",
		component: "mes/qc/ipqc/save",
		hidden: true,
		parent: "/mes/qc",
		meta: {
			icon: "el-icon-edit",
			title: "过程检验详情",
			hidden: true,
		}
	},
	{
		name: "iqcEditTable",
		path: "/qc/iqc/save",
		component: "mes/qc/iqc/save",
		hidden: true,
		parent: "/mes/qc",
		meta: {
			icon: "el-icon-edit",
			title: "来料检验详情",
			hidden: true,
		}
	},
	{
		name: "oqcEditTable",
		path: "/qc/oqc/save",
		component: "mes/qc/oqc/save",
		hidden: true,
		parent: "/mes/qc",
		meta: {
			icon: "el-icon-edit",
			title: "出货检验详情",
			hidden: true,
		}
	},
	{
		name: "lqcEditTable",
		path: "/qc/lqc/save",
		component: "mes/qc/lqc/save",
		hidden: true,
		parent: "/mes/qc",
		meta: {
			icon: "el-icon-edit",
			title: "实验室检验详情",
			hidden: true,
		}
	},
	{
		name: "assistplanSaveTable",
		path: "/assist/assistplan/save",
		component: "mes/assist/assistplan/save",
		hidden: true,
		parent: "/mes/assist",
		meta: {
			icon: "el-icon-edit",
			title: "辅助作业方案编辑",
			hidden: true,
		}
	},
	{
		name: 'reportItem',
		path: '/feedback/reportItem',
		component: 'mes/pro/feedback/reportItem',
		hidden: true,
		parent: '/pm',
		meta: {
			title: '查看报工记录明细',
			hidden: true
		}
	},
	{
		name: 'kilnAdd',
		path: '/assist/kilnAdd',
		component: 'mes/assist/assistlog/kilnAdd',
		hidden: true,
		parent: '/mes/assist',
		meta: {
			title: '新增窑炉烧成记录',
			hidden: true
		}
	},
	{
		name: "issueStockAdd",
		path: "/noticeDetail",
		component: "system/notice/detail",
		hidden: true,
		parent: "/system",
		meta: {
			icon: "el-icon-edit",
			title: "通知公告详情",
			hidden: true,
		}
	}
	, {
		name: "productBatchSave",
		path: "/wm/productrecpt/batch",
		component: "mes/wm/productrecpt/saveBatch",
		hidden: true,
		parent: "/mes/wm",
		meta: {
			icon: "el-icon-edit",
			title: "批量产品入库",
			hidden: true,
		}
	},
	{
		name: 'addTransfer',
		path: '/transfer/add',
		component: 'mes/wm/transfer/add',
		hidden: true,
		parent: '/mes/wm',
		meta: {
			title: '新增调拨',
			hidden: true
		}
	},
	{
		name: 'addStocktaking',
		path: '/stocktaking/add',
		component: 'mes/wm/stocktaking/save',
		hidden: true,
		parent: '/mes/wm',
		meta: {
			title: '新增盘点计划',
			hidden: true
		}
	},
	{
		name: 'addCheckRecord',
		path: '/checkrecord/add',
		component: 'mes/dv/checkrecord/save',
		hidden: true,
		parent: '/mes/dv',
		meta: {
			title: '新增点检保养记录',
			hidden: true
		}
	},
	{
		name: 'addSaleOrder',
		path: '/order/sale/add',
		component: 'erp/order/sale/save',
		hidden: true,
		parent: '/sale',
		meta: {
			title: '新增销售订单',
			hidden: true
		}
	},
	{
		name: 'saleOrderDetail',
		path: '/order/sale/detail',
		component: 'erp/order/sale/detail',
		hidden: true,
		parent: '/sale',
		meta: {
			title: '销售订单详情',
			hidden: true
		}
	},
	{
		name: 'addPurchaseOrder',
		path: '/order/purchase/add',
		component: 'erp/order/purchase/save',
		hidden: true,
		parent: '/purchase',
		meta: {
			title: '新增采购订单',
			hidden: true
		}
	},
	{
		name: 'purchaseOrderDetail',
		path: '/order/purchase/detail',
		component: 'erp/order/purchase/detail',
		hidden: true,
		parent: '/purchase',
		meta: {
			title: '采购订单详情',
			hidden: true
		}
	},
	{
		name: 'saleOrderOutDetail',
		path: '/order/saleOut/detail',
		component: 'erp/order/saleOrderOut/detail',
		hidden: true,
		parent: '/mes/wm',
		meta: {
			title: '销售订单详情',
			hidden: true
		}
	},
	{
		name: 'addPackingSingle',
		path: '/packing/single/add',
		component: 'mes/wm/packing/save',
		hidden: true,
		parent: '/mes/wm',
		meta: {
			title: '新建打包',
			hidden: true
		}
	},
	{
		name: 'addPackingBatch',
		path: '/packing/batch/add',
		component: 'mes/wm/packingBatch/save',
		hidden: true,
		parent: '/mes/wm',
		meta: {
			title: '新建批量打包',
			hidden: true
		}
	},
	{
		name: "vendorEdit",
		path: "/vendor/edit",
		component: "erp/vendor/vendorManage/save",
		hidden: true,
		parent: "/erp/vendor",
		meta: {
			icon: "el-icon-edit",
			title: "修改供应商",
			hidden: true,
		}
	},
	{
		name: "clientEdit",
		path: "/client/edit",
		component: "erp/client/clientManage/save",
		hidden: true,
		parent: "/erp/client",
		meta: {
			icon: "el-icon-edit",
			title: "修改客户",
			hidden: true,
		}
	},
	{
		name: "staffRecordEdit",
		path: "/staffRecord/edit",
		component: "erp/salaryManagement/staffRecord/save",
		hidden: true,
		parent: "/erp/salaryManagement",
		meta: {
			icon: "el-icon-edit",
			title: "员工档案",
			hidden: true,
		}
	},
	{
		name: "incomeEditTable",
		path: "/finance/income/save",
		component: "erp/finance/income/save",
		hidden: true,
		parent: "/finance",
		meta: {
			icon: "el-icon-edit",
			title: "收入管理详情",
			hidden: true,
		}
	},
	{
		name: "compensationPlanEdit",
		path: "/compensationPlan/edit",
		component: "erp/salaryManagement/compensationPlan/save",
		hidden: true,
		parent: "/erp/salaryManagement",
		meta: {
			icon: "el-icon-edit",
			title: "薪酬方案",
			hidden: true,
		}
	},
	{
		name: "expenseEditTable",
		path: "/finance/expense/save",
		component: "erp/finance/expense/save",
		hidden: true,
		parent: "/finance",
		meta: {
			icon: "el-icon-edit",
			title: "支出管理详情",
			hidden: true,
		}
	},
	{
		name: "invoiceEditTable",
		path: "/finance/invoice/save",
		component: "erp/finance/invoice/save",
		hidden: true,
		parent: "/finance",
		meta: {
			icon: "el-icon-edit",
			title: "开票管理详情",
			hidden: true,
		}
	},
	{
		name: "receivingInvoiceEditTable",
		path: "/finance/receivingInvoice/save",
		component: "erp/finance/receivingInvoice/save",
		hidden: true,
		parent: "/finance",
		meta: {
			icon: "el-icon-edit",
			title: "到票管理详情",
			hidden: true,
		}
	},
	{
		name: "salaryEditTable",
		path: "/salary/edit",
		component: "erp/salaryManagement/salary/save",
		hidden: true,
		parent: "/erp/salaryManagement",
		meta: {
			icon: "el-icon-edit",
			title: "工资表",
			hidden: true,
		}
	},
	{
		name: "productsalseOpen",
		path: "/wm/productsalse/open",
		component: "mes/wm/productsalse/save",
		hidden: true,
		parent: "/mes/wm",
		meta: {
			icon: "el-icon-edit",
			title: "销售出库",
			hidden: true,
		}
	},
	{
		name: "flyflowEdit",
		path: "/workflow/flyflowEdit",
		component: "workflow/flow/group/flyflowEdit",
		hidden: true,
		parent: "/system",
		meta: {
			icon: "el-icon-edit",
			title: "流程编辑",
			hidden: true,
		}
	},
	{
		name: "flyflowAdd",
		path: "/workflow/flyflowAdd",
		component: "workflow/flow/group/flyflowAdd",
		hidden: true,
		parent: "/system",
		meta: {
			icon: "el-icon-edit",
			title: "流程添加",
			hidden: true,
		}
	},
	{
		name: "assistqcEditTable",
		path: "/assist/assistqc/save",
		component: "mes/assist/assistqc/save",
		hidden: true,
		parent: "/mes/assist",
		meta: {
			icon: "el-icon-edit",
			title: "窑炉烧成检验详情",
			hidden: true,
		}
	},
]

export default routes;
