import config from "@/config"
import http from "@/utils/request"
export default {
  listRole:{
    url: `${config.API_URL}/system/role/list`,
    name: "查询角色列表",
    get: async function(query){
      return await http.get(this.url,query);
    }
  },
  getRole:{
      url: `${config.API_URL}/system/role/`,
      name: "查询角色详细",
      get: async function(roleId){
        return await http.get(this.url+roleId,roleId);
      }
    },
    addRole:{
      url: `${config.API_URL}/system/role`,
      name: "新增角色",
      post: async function(data){
        return await http.post(this.url,data);
      }
    },
    updateRole:{
      url: `${config.API_URL}/system/role`,
      name: "修改角色",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    delRole:{
      url: `${config.API_URL}/system/role/`,
      name: "删除角色",
      delete: async function(roleId){
        return await http.delete(this.url+roleId,roleId);
      }
    },
    dataScope:{
      url: `${config.API_URL}/system/role/dataScope`,
      name: "角色数据权限",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    changeRoleStatus:{
      url: `${config.API_URL}/system/role/changeStatus`,
      name: "角色状态修改",
      put: async function(roleId, status){
        const data = {
          roleId,
          status
        }
        return await http.put(this.url,data);
      }
    },
    allocatedUserList:{
      url: `${config.API_URL}/system/role/authUser/allocatedList`,
      name: "查询角色已授权用户列表",
      get: async function(data){
        return await http.get(this.url,data);
      }
    },
    allocatedUserList1:{
      url: `${config.API_URL}/system/role/authUser/allocatedList1`,
      name: "查询角色已授权用户列表",
      get: async function(data){
        return await http.get(this.url,data);
      }
    },
    unallocatedUserList:{
      url: `${config.API_URL}/system/role/authUser/unallocatedList`,
      name: "查询角色未授权用户列表",
      get: async function(data){
        return await http.get(this.url,data);
      }
    },
    unallocatedUserList1:{
      url: `${config.API_URL}/system/role/authUser/unallocatedList1`,
      name: "查询角色未授权用户列表",
      get: async function(data){
        return await http.get(this.url,data);
      }
    },
    authUserCancel:{
      url: `${config.API_URL}/system/role/authUser/cancel`,
      name: "取消用户授权角色",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    authUserCancelAll:{
      url: `${config.API_URL}/system/role/authUser/cancelAll`,
      name: "批量取消用户授权角色",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    authUserCancelAll1:{
      url: `${config.API_URL}/system/role/authUser/cancelAll1`,
      name: "批量取消用户授权角色",
      get: async function(data){
        return await http.get(this.url,data);
      }
    },
    authUserSelectAll:{
      url: `${config.API_URL}/system/role/authUser/selectAll`,
      name: "授权用户选择",
      put: async function(data){
        return await http.put(this.url,data);
      }
    },
    authUserSelectAll1:{
      url: `${config.API_URL}/system/role/authUser/selectAll1`,
      name: "授权用户选择",
      get: async function(data){
        return await http.get(this.url,data);
      }
    },
    exportFile: {
      url: `${config.API_URL}/system/role/export`,
      name: "导出附件",
      post: async function(data, config={}){
        return await http.postDom(this.url, data, config,"role");
      }
    }

}
