import config from "@/config"
import http from "@/utils/request"
export default {
  listPost:{
    url: `${config.API_URL}/system/post/list`,
    name: "查询岗位列表",
    get: async function(query){
      return await http.get(this.url,query);
    }
  },
  listAllPost:{
    url: `${config.API_URL}/system/post/listAll`,
    name: "查询所有可用岗位,以列表方式返回",
    get: async function(query){
      return await http.get(this.url,query);
    }
  },
  getPost:{
    url: `${config.API_URL}/system/post/`,
    name: "查询岗位详细",
    get: async function(postId){
      return await http.get(this.url + postId,postId);
    }
  },
  addPost:{
    url: `${config.API_URL}/system/post`,
    name: "新增岗位",
    post: async function(data){
      return await http.post(this.url ,data);
    }
  },
  updatePost:{
    url: `${config.API_URL}/system/post`,
    name: "修改岗位",
    put: async function(data){
      return await http.put(this.url ,data);
    }
  },
  delPost:{
    url: `${config.API_URL}/system/post/`,
    name: "删除岗位",
    delete: async function(postId){
      return await http.delete(this.url+postId ,postId);
    }
  }
}
