import config from "@/config"
import http from "@/utils/request"

export default {
	report: {
		getReport: {
			url: `${config.API_URL}/mes/report/`,
			name: "查看报表",
			responseType: 'arraybuffer',
			get: async function (reportName) {
				return await http.get(this.url + reportName);
			}
		},
		getReport2: {
			url: `${config.API_URL}/jasper-report/previewPrint/`,
			name: "查看报表",
			responseType: 'arraybuffer',
			get: async function (reportName) {
				return await http.get(this.url + reportName);
			}
		},

	},
	ureport: {
		listReport: {
			url: `${config.API_URL}/ureportM/list`,
			name: "查询报表管理列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getReport: {
			url: `${config.API_URL}/ureportM/`,
			name: "查询报表管理详细",
			get: async function (id) {
				return await http.get(this.url + id);
			}
		},
		addReport: {
			url: `${config.API_URL}/ureportM`,
			name: "新增报表管理",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateReport: {
			url: `${config.API_URL}/ureportM`,
			name: "修改报表管理",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delReport: {
			url: `${config.API_URL}/ureportM/`,
			name: "删除报表管理",
			delete: async function (id) {
				return await http.delete(this.url + id);
			}
		},
		updateRoleReport: {
			url: `${config.API_URL}/ureportM/updateRoleReport`,
			name: "权限设置",
			post: async function (data) {
				return await http.post(this.url,data);
			}
		},
		decideReport: {
			url: `${config.API_URL}/ureportM/decideReport`,
			name: "判断是否被其它报表占用",
			get: async function (data) {
				return await http.get(this.url,data);
			}
		},
		buttonReport: {
			url: `${config.API_URL}/ureportM/buttonReport`,
			name: "查找按钮关联报表",
			get: async function (data) {
				return await http.get(this.url,data);
			}
		},
	},
	home:{
		queryHomeMap:{
			url: `${config.API_URL}/ureportHome/queryHomeMap/`,
			name: "dashboard",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		queryQcNumber:{
			url: `${config.API_URL}/ureportHome/queryQcNumber/`,
			name: "获取报工统计",
			get: async function (type) {
				return await http.get(this.url+type);
			}
		},
		queryDefectMap:{
			url: `${config.API_URL}/ureportHome/queryDefectMap/`,
			name: "获取缺陷占比",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		queryUnqualifiedMap:{
			url: `${config.API_URL}/ureportHome/queryUnqualifiedMap/`,
			name: "获取不合格率",
			get: async function (type) {
				return await http.get(this.url+type);
			}
		},
	},
	// 智能车间生产管控大屏
	chipworkshop:{
		lineWorkshopMachinery:{
			url: `${config.API_URL}/screenChipWorkShop/queryLineWorkshopMachineryMap/`,
			name: "获取智能车间生产管控数据-设备数、产线数、车间数",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		queryProcessWorkRanking:{
			url: `${config.API_URL}/screenChipWorkShop/queryProcessWorkRanking/`,
			name: "获取智能车间生产管控数据-今日工序报工排名",
			get: async function (query) {
				return await http.get(this.url+query);
			}
		},
		queryPlanTaskWorkorder:{
			url: `${config.API_URL}/screenChipWorkShop/queryPlanTaskWorkorder/`,
			name: "获取智能车间生产管控数据-今日统计",
			get: async function (query) {
				return await http.get(this.url+query);
			}
		},
		queryDefectProportion:{
			url: `${config.API_URL}/screenChipWorkShop/queryDefectProportion`,
			name: "获取智能车间生产管控数据-缺陷占比",
			get: async function (query) {
				return await http.get(this.url,query);
			}
		},
		queryWorkOrderProgress:{
			url: `${config.API_URL}/screenChipWorkShop/queryWorkOrderProgress`,
			name: "获取智能车间生产管控数据-工单进度",
			get: async function (query) {
				return await http.get(this.url,query);
			}
		},
		queryWorkOrderFinished:{
			url: `${config.API_URL}/screenChipWorkShop/queryWorkOrderFinished`,
			name: "获取智能车间生产管控数据-产品完工",
			get: async function (query) {
				return await http.get(this.url,query);
			}
		},
		getPassRateMonth:{
			url: `${config.API_URL}/screenChipWorkShop/getPassRateMonth`,
			name: "获取智能车间生产管控数据-产品合格率",
			get: async function (query) {
				return await http.get(this.url,query);
			}
		}
	},
	andon: {
		listProportion:{
			url: `${config.API_URL}/mes/databoard/andon/listProportion`,
			name: "安灯异常看板",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	},
	inOutWarehouse: {
		listOutWarehouse:{
			url: `${config.API_URL}/mes/databoard/inOutWarehouse/listOutWarehouse`,
			name: "出库任务",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listInWarehouse:{
			url: `${config.API_URL}/mes/databoard/inOutWarehouse/listInWarehouse`,
			name: "入库任务",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	},
	erpHome:{
		queryErpFinanceMap:{
			url: `${config.API_URL}/erp/homepageReport/queryErpFinanceMap/`,
			name: "获取ERP首页财务数据统计",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		queryErpIncomeAndExpenseMap:{
			url: `${config.API_URL}/erp/homepageReport/queryErpIncomeAndExpenseMap/`,
			name: "获取ERP首页近12个月收入与支出详情",
			get: async function (type) {
				return await http.get(this.url,type);
			}
		},
		queryErpFundMap:{
			url: `${config.API_URL}/erp/homepageReport/queryErpFundMap/`,
			name: "获取ERP首页近12个月末现金变动趋势",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	},
	plannedCompletion:{
		plannedCompletionRate:{
			url: `${config.API_URL}/plannedCompletion/plannedCompletionRate`,
			name: "统计工序计划完成率",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		}
	}
}
