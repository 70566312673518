<template>
	<el-row style="width: 100%;" class="progress-bar">
		<div :style="{'width': calcSpan(list.length)}" v-for="(item, index) in list" :key="index">
			<el-tooltip
				class="box-item"
				effect="dark"
				placement="bottom">
				<div class="progress-bar-outer"
					 :class="{'progress-bar-last': index == list.length - 1, 'progress-bar-first': index == 0}"
					 :style="{backgroundColor: hexToRgb(item.color, 0.3), 'border-radius': (index == list.length - 1 && index == 0 ? '10px': '')}">
					<div class="progress-bar-inner"
						 :class="{'progress-bar-last': index == list.length - 1, 'progress-bar-first': index == 0}"
						 :style="{width: item.percent + '%', backgroundColor: item.color, 'border-radius': (index == list.length - 1 && index == 0 ? '10px': '')}">
						<span class="progress-text">{{ item.percent + '%' }}</span>
					</div>
				</div>
				<template #content>
					<span v-if="item.content" v-html="item.content"></span>
				</template>
			</el-tooltip>
		</div>
	</el-row>
</template>

<script>
export default {
	name: "scProgress",
	props: {
		list: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		calcSpan(size) {
			if (size == 0) {
				return 0 + "%";
			}
			return (100.00000000 / size) + '%';
		},
		hexToRgb(hex, opcity) {
			let arr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			let r = parseInt(arr[1], 16);
			let g = parseInt(arr[2], 16);
			let b = parseInt(arr[3], 16);
			return 'rgba(' + r + ',' + g + ',' + b + ',' + opcity + ')';
		}
	}
}
</script>

<style scoped>

.progress-bar-outer {
	width: 100%;
	height: 20px;
	align-items: center;
	display: flex;
}

.progress-bar-inner {
	height: 100%;
	transition: width 0.6s ease;
	text-align: center;
	line-height: 80%;
}

.progress-text {
	font-size: 12px !important;
	color: #ffffff;
	line-height: 170%;
	padding-left: 3px;
}

.progress-bar-first {
	border-radius: 10px 0 0 10px;
}

.progress-bar-last {
	border-radius: 0 10px 10px 0;
}
</style>
