// import api from '@/api'

const custom = {
	state: {
		getSetName: '',
		getSetLogol: '',
		getSetVueLogol: '',
	},
	mutations: {
		SET_SYS_PROJECT_NAME: (state, getSetName) => {
			state.getSetName = getSetName
		},
		SET_SYS_LOGO: (state, getSetLogol) => {
			state.getSetLogol = getSetLogol
		},
		SET_SYS_VUE_LOGO: (state, getSetVueLogol) => {
			state.getSetVueLogol = getSetVueLogol
		},
	},
	actions: {
		// 获取项目名称信息
		GetSysProjectName({commit},getSetName) {
			commit('SET_SYS_PROJECT_NAME', getSetName)
		},
		// 获取项目名称信息
		GetSysLogo({commit},getSetLogol) {
			commit('SET_SYS_LOGO', getSetLogol)
		},
		// 获取项目页面上logo
		GetSysVueLogo({commit},getSetVueLogol) {
			commit('SET_SYS_VUE_LOGO', getSetVueLogol);
		},
	}
}

export default custom
